import { Box, Group, Paper, Stack } from "@mantine/core";
import { BreadCrumbsPageBlock } from "components/_common/_page/_blocks/BreadCrumbsPageBlock";
import { ProfileFields } from "packages/profileFields/components";
import { ProfileLayout } from "pages/_private/ProfilePage/components/ProfileLayout";
import { Helmet } from "react-helmet-async";
import { ProfileAvatar } from "./components/ProfileAvatar";
import { useConfig } from "./config";

const breadCrumbsPageBlock = [{ title: "Главная", href: "/main" }, { title: "Профиль" }];

const ProfileCommonPage = () => {
  const config = useConfig();

  return (
    <>
      <Helmet>
        <title>Профиль</title>
        <meta name="description" content="Запуск - Профиль." />
        <meta property="og:title" content="Zapusk - Profile" />
        <meta property="og:description" content="Zapusk - Profile" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Box pos="absolute" top="0">
        <BreadCrumbsPageBlock items={breadCrumbsPageBlock} isWhiteBc={false} />
      </Box>
      <ProfileLayout title={"Профиль"}>
        <Paper>
          <Group align={"top"} justify="center" gap={"xl"}>
            <ProfileAvatar />
            <Stack style={{ flexGrow: 1 }}>
              <ProfileFields {...config} />
            </Stack>
          </Group>
        </Paper>
      </ProfileLayout>
    </>
  );
};

export default ProfileCommonPage;
