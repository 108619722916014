import { Center } from "@mantine/core";
import { MainLoader } from "shared/ui/_loaders/MainLoader";

type Props<T> = {
  pending?: boolean;
  error?: string | null | boolean;
  errorComponent?: (error: string | null | boolean) => React.ReactNode | React.ReactNode[];
  data: T | null;
  children: (data: T) => React.ReactNode | React.ReactNode[];
};

export function PageView<T>({ pending, error, data, children }: Props<T>) {
  if (pending) {
    return (
      <Center mt={200} mb={200}>
        <MainLoader />
      </Center>
    );
  }

  if (error) {
    return <div>Ошибка</div>;
  }

  if (data) {
    return <>{children(data)}</>;
  }

  return null;
}
