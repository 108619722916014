import {
  Badge,
  Box,
  Button,
  Card,
  Divider,
  Flex,
  Group,
  Image,
  Paper,
  rem,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { IconPointFilled } from "@tabler/icons-react";
import { AccessChecker } from "components/_access/AccessChecker";
import { AccessLimited } from "components/_access/AccessLimited/accessLimited";
import { useNavigate } from "react-router-dom";
import { PROJECT_STATUSES } from "shared/const/projects";
import { formatLargeNumber } from "shared/helpers/formatLargeNumber";
import { toMoney } from "shared/helpers/toMoney";
import { DEFAULT_GRADIENT } from "shared/hooks/useGradient";
import { useMobile } from "shared/hooks/useMobile";
import { Project } from "shared/types/projects";

// const favoriteData = [
//   {
//     label: "Добавить в избранное",
//     color: "dark.0",
//     icon: <IconStar />,
//   },
//   {
//     label: "Убрать из избранного",
//     color: "gold.4",
//     icon: <IconStarFilled />,
//   },
// ];

export const ProjectCard = ({
  badge,
  categories,
  description,
  name,
  logo,
  status,
  id,
  images,
  activeRound,
  investors,
  rounds,
}: Project) => {
  const navigate = useNavigate();
  const projectPath = `/projects/${id}/about`;
  const { setOption } = useMobile();

  const raisedAll = (activeRound?.raised || 0) + (activeRound?.activeOffer?.raised || 0);

  const handleNavigate = () => navigate(projectPath);

  if (status === "roundComplete") {
    return (
      <Card padding="lg" bg={"gray.0"} pb={-25} maw={400} radius="md">
        <Card.Section>
          <Image src={images[0]} h={250} />
        </Card.Section>
        <Box pos={"relative"} top={-25}>
          <Stack gap={"sm"}>
            <Paper
              component={Group}
              align={"center"}
              justify={"center"}
              withBorder
              p={3}
              w={50}
              h={50}
            >
              <Image radius={"md"} fit={"contain"} src={logo} />
            </Paper>
            <Group gap={5}>
              <Badge size={"xs"} c={"red"} bg={PROJECT_STATUSES[status].color} radius={3}>
                <Flex align={"center"}>
                  <Text component={"span"} fw={500} size={rem(10)} c={"white"}>
                    {PROJECT_STATUSES[status].label}
                  </Text>
                </Flex>
              </Badge>
              {badge && (
                <Badge bg={"blue"} size={"xs"} radius={3}>
                  {badge}
                </Badge>
              )}
            </Group>
            <Title fw={500} size={20} order={6}>
              {name}
            </Title>
            <Text size={rem(12)}>{description}</Text>

            {!!rounds.reduce((acc, { raised }) => acc + raised, 0) && (
              <Box>
                <Text size={rem(12)}>Собрано</Text>
                <AccessChecker rejectChildren={<AccessLimited />}>
                  <Text
                    pt={5}
                    fw={600}
                    variant={"gradient"}
                    size={rem(20)}
                    gradient={DEFAULT_GRADIENT}
                  >
                    {formatLargeNumber(
                      rounds.reduce((acc, { raised }) => acc + raised, 0) || 0,
                      "currency",
                    )}
                  </Text>
                </AccessChecker>
              </Box>
            )}
          </Stack>
        </Box>
      </Card>
    );
  }

  return (
    <Card padding="lg" bg={"gray.0"} pb={-25} maw={400} radius="md">
      <Card.Section>
        <Image src={images[0]} h={250} />
      </Card.Section>
      <Box pos={"relative"} top={-25}>
        <Stack gap={"sm"}>
          <Paper
            component={Group}
            align={"center"}
            justify={"center"}
            withBorder
            p={3}
            w={50}
            h={50}
          >
            <Image radius={"md"} fit={"contain"} src={logo} />
          </Paper>
          <Group gap={5}>
            <Badge
              size={"xs"}
              c={"red"}
              bg={PROJECT_STATUSES[status].color}
              rightSection={<IconPointFilled />}
              radius={3}
            >
              <Flex align={"center"}>
                <Text component={"span"} fw={500} size={rem(10)} c={"white"}>
                  {PROJECT_STATUSES[status].label}
                </Text>
              </Flex>
            </Badge>
            {badge && (
              <Badge bg={"blue"} size={"xs"} radius={3}>
                {badge}
              </Badge>
            )}
          </Group>
          <Title fw={500} size={20} order={6}>
            {name}
          </Title>
          <Text h={60} lineClamp={5} size={rem(12)}>
            {description}
          </Text>
          <AccessChecker rejectChildren={<AccessLimited />}>
            {!!raisedAll && (
              <Box>
                <Text size={rem(12)}>Уже собрано</Text>

                <Text
                  pt={5}
                  fw={600}
                  variant={"gradient"}
                  size={rem(20)}
                  gradient={DEFAULT_GRADIENT}
                >
                  {formatLargeNumber(raisedAll, "currency")}
                </Text>
              </Box>
            )}
          </AccessChecker>
          <AccessChecker>
            <Box>
              <Divider color={"gray.1"} size={"xs"} />
              {!!investors?.length && (
                <Group pl={15} pt={8} pb={8} gap={5}>
                  <Text size={rem(12)} fw={500}>
                    {investors.length}
                  </Text>
                  <Text size={rem(12)}>инвестора</Text>
                </Group>
              )}
              <Divider color={"gray.1"} size={"xs"} />
              <Group pl={15} pt={8} pb={8} gap={5}>
                <Text size={rem(12)} fw={500}>
                  {toMoney(activeRound.activeOffer?.minAmount)}
                </Text>
                <Text size={rem(12)}>мин. инвестиция</Text>
              </Group>
              <Divider color={"gray.1"} size={"xs"} />
            </Box>
          </AccessChecker>
          <Button onClick={handleNavigate} variant={"outline"}>
            Подробнее
          </Button>
        </Stack>
      </Box>
    </Card>
  );
};
