import React, { useEffect } from "react";
import { useSendError } from "shared/hooks/useSendError";
import { AnyObject } from "shared/types/common";
import { Fx } from "../types/api";
import { useSessionID } from "./_auth/useSessionID";

export const useFetch = <T, U>(requestFx: Fx<T, U, Error>) => {
  const [pending, setPending] = React.useState(false);
  const [error, setError] = React.useState<null | string>(null);
  const [data, setData] = React.useState<U | null>(null);

  const fetchData = async (props: T) => {
    const fetch = async () => {
      setPending(true);
      setError(null);
      try {
        const data = (await requestFx(props)) as U;
        setData(data);
      } catch (err: any) {
        setError(err.response.data.msg);
      } finally {
        setPending(false);
      }
    };
    await fetch();
    return;
  };

  useSendError(error);

  const reset = () => setData(null);

  return { pending, error, setError, fetchData, data, reset };
};

export const useFetchImmediate = <FXProps extends { params: any; data: any }, FXData>({
  requestFx,
  requestData,
}: {
  requestFx: (props: FXProps) => Promise<FXData>;
  requestData?: FXProps["data"];
}) => {
  const [pending, setPending] = React.useState(false);
  const [error, setError] = React.useState<null | string>(null);
  const [data, setData] = React.useState<FXData | null>(null);

  const sessionID = useSessionID()!;
  const preparedParams = { sessionID, ...requestData?.params };
  const preparedData = requestData?.data ? { data: requestData.data } : {};

  const preparedFxProps = { params: preparedParams, ...preparedData };

  useEffect(() => {
    const fetchData = async (props: FXProps) => {
      setPending(true);
      try {
        const data = await requestFx(props);
        setData(data);
      } catch (err: any) {
        setError(err.response.data.msg);
      } finally {
        setPending(false);
      }
    };
    fetchData(preparedFxProps as FXProps);
  }, []);

  const reset = () => setData(null);

  return { pending, error, data, reset };
};
export const useFetchV2 = <
  FXProps extends { params: AnyObject<any>; data: AnyObject<any> },
  FXData,
>({
  requestFx,
}: {
  requestFx: (props: FXProps) => Promise<FXData>;
}) => {
  const [state, setState] = React.useState({
    pending: false,
    error: null as null | string,
    responseData: null as FXData | null,
  });

  const sessionID = useSessionID()!;
  const params = { sessionID } as FXProps["params"];

  const fetchData = React.useCallback(async (props: FXProps) => {
    setState((prev) => ({ ...prev, pending: true }));
    try {
      const data = await requestFx(props);
      setState((prev) => ({ ...prev, responseData: data }));
    } catch (err: any) {
      setState((prev) => ({ ...prev, error: err.response.data.msg }));
    } finally {
      setState((prev) => ({ ...prev, pending: false }));
    }
  }, []);

  const sendFetch = (data: FXProps["data"]) => {
    const props = { params, data } as FXProps;
    fetchData(props);
  };

  return {
    pending: state.pending,
    error: state.error,
    data: state.responseData,
    sendFetch,
  };
};
