import { Box, Paper } from "@mantine/core";
import { useDeals } from "modules/investorRole/hooks/_deals/useDeals";
import { useNavigate } from "react-router-dom";
import { useCreateTableBody } from "shared/hooks/useCreateTableBody";
import { Deal } from "shared/types/deals";
import BootstrapGrid from "shared/ui/BootstrapGrid";
import { Table } from "shared/ui/_tables/Table";
import { filterDealsConfig } from "./config";

const { titles, dealRow } = filterDealsConfig;
const FilterDealsPage = () => {
  const deals = useDeals();
  const navigate = useNavigate();
  const dealsBody = useCreateTableBody<Deal>({
    data: deals.data,
    create: dealRow,
    onClick: (id: string) => navigate("/deal/" + id),
  });

  return (
    <BootstrapGrid.Row style={{ overflow: "auto", flexGrow: 1 }}>
      <BootstrapGrid.Item component={Box} h={"100%"} col={12} without={"Pb"} grow={0}>
        <Paper h={"100%"}>
          <Table h={"100%"} titles={titles} pending={deals.pending} body={dealsBody} />
        </Paper>
      </BootstrapGrid.Item>
    </BootstrapGrid.Row>
  );
};

export default FilterDealsPage;
