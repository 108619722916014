import { PrivateRoute } from "components/_private/PrivateRoute";
import { LazyRoundsPage } from "modules/projectRole/pages/RoundsPage/lazy";
import { LazyCreateOfferPage } from "modules/projectRole/pages/RoundsPage/pages/CreateOfferPage/lazy";
import { LazyEditRoundPage } from "modules/projectRole/pages/RoundsPage/pages/EditRoundPage/lazy";
import { ROUTES } from "shared/const/ROUTES";
import { AnyObject } from "shared/types/common";
import { Route } from "shared/types/routes";
import { LazyCreateRoundPage } from "../../pages/RoundsPage/pages/CreateRoundPage/lazy";

export const projectRoleRoundsRoute: AnyObject<Route> = {
  privateRounds: {
    Element: () => <PrivateRoute type={"rounds"} />,
    children: {
      rounds: {
        Element: LazyRoundsPage,
        path: ROUTES.PROJECT.ROUNDS.ALL_ROUNDS,
      },
      createRound: {
        Element: LazyCreateRoundPage,
        path: ROUTES.PROJECT.ROUNDS.CREATE_ROUND,
      },
      editRound: {
        Element: LazyEditRoundPage,
        path: ROUTES.PROJECT.ROUNDS.EDIT_ROUND,
      },
      createOffer: {
        Element: LazyCreateOfferPage,
        path: ROUTES.PROJECT.ROUNDS.OFFERS.CREATE_OFFER(":roundId"),
      },
    },
  },
};
