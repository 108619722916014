import { accountFx, GetUserAccountsResponse } from "shared/api/_accounts/account";
import { SIDParams } from "shared/types/api";
import { FxSlice } from "shared/types/store";
import { createSimpleStore } from "stores/_helpers/factories/createSimpleStore";

type Fxs = { fetchGet: FxSlice<SIDParams> };

const { getByUser } = accountFx;
export const useUsersAccounts = createSimpleStore<GetUserAccountsResponse[], Fxs>({
  fetchGet: getByUser,
});
