import { Anchor } from "@mantine/core";
import React from "react";

type Props = {
  href?: string;
  children: React.ReactNode | React.ReactNode[];
};
export const ExternalLink = ({ href, children }: Props) => (
  <Anchor component={"a"} target={"_blank"} variant={"transparent"} href={href}>
    {children}
  </Anchor>
);
