import { Button, Group, Paper, Stack, Text, Title } from "@mantine/core";
import { IconLock } from "@tabler/icons-react";
import { Link } from "react-router-dom";

export const LimitedAccessComponent = () => {
  return (
    <Paper component={Stack}>
      <Group gap={10} justify={"center"}>
        <IconLock size={30} />
        <Title ta={"center"} order={2}>
          Доступ ограничен
        </Title>
      </Group>
      <Text ta={"center"}>
        К сожалению, вы не авторизовались, поэтому мы не можем раскрыть вам детали инвестиционного
        предложения в соответствии с требованиями Центрального Банка.
      </Text>
      <Text ta={"center"}>
        Чтобы получить полный доступ к информации о проекте и его инвестиционном предложении,
        пожалуйста, пройдите регистрацию, либо войдите в свой аккаунт.
      </Text>
      <Group gap={10} justify={"center"}>
        <Button component={Link} to={"/auth/sign-up"}>
          Присоединиться
        </Button>
        <Button variant={"subtle"} component={Link} to={"/auth/sign-in"}>
          Войти
        </Button>
      </Group>
    </Paper>
  );
};
