import { Divider, Group, Skeleton, Stack, Title } from "@mantine/core";
import React, { FC } from "react";
import { emptyArr } from "shared/helpers/emptyArr";
import { getCompByType } from "../config";
import { MaybeNestedFieldsProfile } from "../types";

interface ProfileFieldsProps {
  fields?: MaybeNestedFieldsProfile;
  pending?: boolean;
  inline?: boolean;
  withoutDivider?: boolean;
}

export const ProfileFields: FC<ProfileFieldsProps> = ({ fields, pending, withoutDivider }) => {
  if (pending) {
    return (
      <>
        {emptyArr(10).map((_, index) => (
          <Skeleton key={index} h={80} w={"100%"} />
        ))}
      </>
    );
  }

  if (!fields || fields.length === 0) {
    return <Title order={3}>Не найдено</Title>;
  }

  const fieldsComponents = fields.map((field, i) => {
    if (Array.isArray(field)) {
      return (
        <Group w={"100%"} grow key={i}>
          {field.map((innerField) => {
            return (
              <Stack key={innerField.label} gap={"sm"}>
                <Stack gap={"xs"}>
                  <Title order={5}>{innerField.label}</Title>
                  {React.createElement(getCompByType(innerField.type), innerField)}
                </Stack>
                {withoutDivider || <Divider />}
              </Stack>
            );
          })}
        </Group>
      );
    }

    if ((field as any)?.text === null) {
      return null;
    }

    return (
      <Stack key={`${field.label}_${i}`} gap={"sm"}>
        <Stack gap={"xs"}>
          <Title order={5}>{field.label}</Title>
          {React.createElement(getCompByType(field.type), field)}
        </Stack>
        {withoutDivider || <Divider />}
      </Stack>
    );
  });

  return <>{fieldsComponents}</>;
};
