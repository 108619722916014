import React from "react";
import { UserRole } from "shared/const/userRole";
import { useUser } from "shared/hooks/_user/useUser";

type Props = {
  children: React.ReactNode;
  rejectChildren?: React.ReactNode;
  isAuthenticated?: boolean;
  role?: UserRole[];
};

export const AccessChecker = ({
  children,
  rejectChildren,
  isAuthenticated = true,
  role = [],
}: Props) => {
  const user = useUser();
  const isRoleCorresponded = !role!.length || user?.role.some((value) => role!.includes(value));
  const isAuthenticatedCorresponded = isAuthenticated === Boolean(user?.id);

  if (isRoleCorresponded && isAuthenticatedCorresponded) {
    return <>{children}</>;
  }

  if (rejectChildren) {
    return <>{rejectChildren}</>;
  }

  return null;
};
