import { LazyFAQPage } from "pages/_private/_others/FAQPage/lazy";
import { LazySupportPage } from "pages/_private/_others/SupportPage/lazy";
import { AnyObject } from "shared/types/common";
import { Route } from "shared/types/routes";

export const othersRoutes: AnyObject<Route> = {
  faq: {
    Element: LazyFAQPage,
    path: "/faq",
  },
  support: {
    Element: LazySupportPage,
    path: "/support",
  },
};
