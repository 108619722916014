import React from "react";
import { useSearchParams as useSearchParamsHook } from "react-router-dom";
import { AnyObject } from "shared/types/common";
import { Hook } from "../types/hooks";

type Params = AnyObject<string | number>;

type Keys = string[];
type Payload = {
  current: Params;
  all: Params;
  set: (newParams: Params) => void;
  delete: (deleteKey: string) => void;
};

export const useSearchParams: Hook<Keys | void, Payload> = (keys) => {
  const [searchParams, setSearchParams] = useSearchParamsHook();
  const [currentParams, setCurrentParams] = React.useState<Params>(() => {
    const newParams: Params = {};
    for (const [param, value] of searchParams) {
      if (!keys || keys.includes(param)) {
        newParams[param] = value;
      }
    }
    return newParams;
  });
  const [allParams, setAllParams] = React.useState<Params>({});
  const setNewParams = (set: React.Dispatch<Params>, keys?: string[]) => {
    const newParams: Params = {};
    for (const [param, value] of searchParams) {
      if (!keys || keys.includes(param)) {
        newParams[param] = value;
      }
    }
    set(newParams);
  };

  const deleteParams = (deleteKey: string) => {
    const param = searchParams.get(deleteKey);

    if (param) {
      searchParams.delete(deleteKey);
      setSearchParams(searchParams);
    }
  };

  const setParams = (newParams: Params) => {
    setSearchParams((params) => ({
      ...params,
      ...newParams,
    }));
  };

  React.useEffect(() => {
    setNewParams(setCurrentParams, keys!);
    setNewParams(setAllParams);
  }, [searchParams]);

  return {
    current: currentParams,
    all: allParams,
    set: setParams,
    delete: deleteParams,
  };
};
