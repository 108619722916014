import { getGradient, MantineGradient, useMantineTheme } from "@mantine/core";

export const DEFAULT_GRADIENT = { deg: 90, from: "#294BFA", to: "#D62A65" };

export const useGradient = (
  props: MantineGradient | undefined = { deg: 90, from: "#294BFA", to: "#D62A65" },
) => {
  const theme = useMantineTheme();

  return getGradient(props, theme);
};
