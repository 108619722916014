import React from "react";
import { CreateRow } from "../types/table";

type Props<Data> = {
  data: Data[] | null;
  create: CreateRow<Data>;
  onClick?: (props?: any) => void;
};

export const useCreateTableBody = <Data>({ data, create, onClick }: Props<Data>) => {
  const tableBody =
    React.useMemo(() => data?.map((item) => create({ data: item, onClick })), [data, onClick]) ||
    null;

  return tableBody;
};
