import { Box, Breadcrumbs, rem, Text, Title } from "@mantine/core";
import { BreadCrumbsPageBlockProps } from "components/_common/_page/_blocks/BreadCrumbsPageBlock/types";
import { useMobile } from "shared/hooks/useMobile";
import BootstrapGrid from "shared/ui/BootstrapGrid";
import { Link } from "shared/ui/_links/Link";
import styles from "./index.module.scss";

export const BreadCrumbsPageBlock = ({
  items,
  title,
  isWhiteBc = true,
}: BreadCrumbsPageBlockProps) => {
  const { setMobileOption } = useMobile();

  const breadCrumbs = items.map((item, index, array) => {
    const options = [
      { c: "gray", disabled: false },
      { c: "black", disabled: true },
    ];
    const isLastItem = index === array.length - 1;
    const c = options[Number(isLastItem)].c;
    const disabled = options[Number(isLastItem)].disabled;

    if (disabled || !item.href) {
      return (
        <Text key={index} c={c}>
          {item.title}
        </Text>
      );
    }

    return (
      <Link c={c} key={index} to={item.href}>
        {item.title}
      </Link>
    );
  });

  return (
    <Box>
      <BootstrapGrid.Row
        component={Box}
        className={isWhiteBc ? styles.whiteBc : styles.grayBc}
        style={{ zIndex: 100 }}
      >
        <BootstrapGrid.Item>
          <Breadcrumbs classNames={styles}>{breadCrumbs}</Breadcrumbs>
        </BootstrapGrid.Item>
      </BootstrapGrid.Row>
      {title && (
        <Title
          order={2}
          ml={rem(10)}
          fw={setMobileOption(500)}
          size={setMobileOption(rem(30))}
          pb={rem(20)}
        >
          {title}
        </Title>
      )}
    </Box>
  );
};
