import {
  AccountEntrepreneur,
  AccountIndividual,
  AccountLegalEntity,
  AccountTypes,
  Passport,
} from "shared/types/account";
import { Fx, OnlySidParams, SIDParams } from "shared/types/api";
import { createApi } from "../_factories/createApi";
import { TopUp } from "./topUp";

const structure = "accounts";
const api = createApi({ structure });

export interface Account {
  id: string;
  verify: boolean;
  balance: number;
  onHold: number;
  invested: number;
  limitNoQualify?: number;
  typeOfAccount: AccountTypes;
  individualsId?: {
    verify: true;
    innPersonal: string;
    passportId?: Passport;
  };
  topUps?: TopUp[];
  qualifyStatus?: QualifyStatuses;
  qualify?: {
    qualified: boolean;
  };
}

export type GetAllParams = {
  sessionID: String;
  typeOfAccount?: string;
};

const getAll: Fx<GetAllParams, GetUserAccountsResponse[], Error> = async (params) =>
  await api().getItems<GetUserAccountsResponse[]>("getByUser", params);

export type GetByNameParams = {
  sessionID: String;
  typeOfAccount: string;
};

export type QualifyStatuses = "qualified" | "notQualified" | "request";

const getByName: Fx<GetByNameParams, Account, Error> = async (params) =>
  await api().getItem<Account>("getByName", params);

export interface GetUserAccountsResponse extends Account {
  BankDetailsIds: {
    bankName?: string;
    biq?: string;
    bankINN: string;
    accountNumber: string;
    corAccountNumber?: string;
  };
}

const getByUser: Fx<OnlySidParams, GetUserAccountsResponse[], Error> = async ({ params }) =>
  await api().getItems<GetUserAccountsResponse[]>("getByUser", params);

export type CreateAccountFxProps = {
  params: SIDParams;
  data:
    | ({
        userId: string;
        passportPhotoFirstPage: string;
        passportPhotoSecondPage: string;
      } & AccountLegalEntity)
    | AccountEntrepreneur
    | AccountIndividual;
};

type CreateAccountFxResponse = any;

export const create: Fx<CreateAccountFxProps, CreateAccountFxResponse, Error> = async ({
  data,
  params,
}) => await api("createaccont").setItem<CreateAccountFxResponse>(data.type, data, { ...params });

export const accountFx = {
  getAll,
  getByName,
  getByUser,
  create,
};
