import { Anchor, Box, Center, Container, Group, Image, Stack, Text } from "@mantine/core";
import bankLogo from "shared/assets/images/bank_logo.svg";
import tgLogo from "shared/assets/images/tg_logo.svg";
import whatsAppLogo from "shared/assets/images/whats-app_logo.svg";
import BootstrapGrid from "shared/ui/BootstrapGrid";
import { Link } from "shared/ui/_links/Link";
import { MainLogo } from "shared/ui/_logos/MainLogo";

export const Footer = () => {
  return (
    <Box component={"footer"} p={"50px 16px"} bg={"gray.0"}>
      <Container size={"xxl"}>
        <BootstrapGrid.Row>
          <BootstrapGrid.Item col={6} without={"Pb"}>
            <Box>
              <Stack>
                <Box>
                  <Center>
                    <MainLogo width={120} />
                  </Center>
                  <Center>
                    <Text pt={10}>ООО "Капитализация"</Text>
                  </Center>
                </Box>
                <Box>
                  <Center>
                    <Image w={150} src={bankLogo} />
                  </Center>
                  <Center pt={10}>
                    <Text w={270} ta={"center"}>
                      <Link
                        to={
                          "https://www.cbr.ru/rbr/rbr_fr/doc/?id=32371#highlight=%D0%BA%D0%B0%D0%BF%D0%B8%D1%82%D0%B0%D0%BB%D0%B8%D0%B7%D0%B0%D1%86%D0%B8%D1%8F"
                        }
                        target={"_blank"}
                      >
                        Лицензия №55
                      </Link>{" "}
                      в реестре инвестиционных платформ ЦБ РФ
                    </Text>
                  </Center>
                </Box>
                <Box>
                  <Center>
                    <Anchor href={"tel:84994330557"}>
                      <Text size={"md"} ta={"center"}>
                        8 (499) 433-05-57
                      </Text>
                    </Anchor>
                  </Center>
                  <Center>
                    <Anchor href={"mailto:hi@zapusk.tech"}>
                      <Text pt={5} size={"md"} ta={"center"}>
                        hi@zapusk.tech
                      </Text>
                    </Anchor>
                  </Center>
                </Box>
                <Group component={Center}>
                  <Image src={tgLogo} />
                  <Image src={whatsAppLogo} />
                </Group>
                <Text ta={"center"} c={"gray.4"} size={"xs"} td="underline">
                  Инвестиции в компании на стадии preIPO через краудинвестинг на Zapusk.tech
                </Text>
              </Stack>
            </Box>
          </BootstrapGrid.Item>
        </BootstrapGrid.Row>
      </Container>
    </Box>
  );
};
