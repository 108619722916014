import { notifications } from "@mantine/notifications";
import React from "react";
import { useSessionID } from "shared/hooks/_auth/useSessionID";
import { AnyObject } from "shared/types/common";
import { FxSlice } from "shared/types/store";
import { SliceModern } from "stores/_helpers/factories/createSliceNew";
import { StoreApi } from "zustand";
import { UseBoundStoreWithEqualityFn } from "zustand/traditional";

type Props<Payload, Params> = {
  useStore: UseBoundStoreWithEqualityFn<StoreApi<SliceModern<Payload, Params>>>;
  fetchKey?: string;
  searchParamsKeys?: string[];
  customParams?: any;
  requestDependencies?: any[];
};

type HookProps = {
  withoutAutoRequest?: boolean;
};
export const createGetItemHook = <Payload, Params>({
  useStore,
  fetchKey = "fetchGetItem",
  searchParamsKeys,
  requestDependencies = [],
  customParams = {},
}: Props<Payload, Params>) => {
  const useHook = ({ withoutAutoRequest }: HookProps = {}) => {
    const { data, reset, set, ...store } = useStore();
    const sessionID = useSessionID()!;
    const { pending, error, fx } = (store as any)[fetchKey] as FxSlice<Params>;
    const fetchParams = { sessionID, ...customParams } as Params;

    const handleGetItem = (customParams?: AnyObject<any>) => {
      const commonParams = { ...fetchParams, ...customParams };
      if (!pending) fx(commonParams);
    };

    React.useEffect(() => {
      if (!pending && !withoutAutoRequest) fx(fetchParams);
    }, requestDependencies);

    React.useEffect(() => {
      const errorNotification = () => ({
        color: "red",
        title: "Произошла ошибка",
        message: error,
      });
      if (error) notifications.show(errorNotification());
    }, [error]);

    return { data, pending, error, handleGetItem, set, reset };
  };

  return useHook;
};
