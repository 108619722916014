import { ErrorFallback } from "components/_error/ErrorFallback";
import { createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import { CommonRouter } from "router/routers/CommonRouter/router";
import { PrivateRouter } from "router/routers/PrivateRouter/router";
import { AuthRouter } from "./routers/AuthRouter/router";

export const router = createBrowserRouter([
  {
    errorElement: <ErrorFallback />,
    children: createRoutesFromElements([CommonRouter(), PrivateRouter(), AuthRouter()]),
  },
]);
