import { Center, Grid, Text } from "@mantine/core";
import { ProjectCard } from "components/project/OldProjectCards/ProjectCard";
import { useMobile } from "shared/hooks/useMobile";
import { Project } from "shared/types/projects";

export const ProjectCards = ({ projects }: { projects: Project[] }) => {
  const { setOption } = useMobile();

  if (!projects.length) {
    return (
      <Center>
        <Text>Нет проектов по заданным параметрам</Text>
      </Center>
    );
  }

  return (
    <Grid>
      {projects.map((item, index) => (
        <>
          <Grid.Col span={{ base: 12, sm: 6, md: 4, lg: 4 }} key={index}>
            {setOption(
              <Center>
                <ProjectCard key={index} {...item} />
              </Center>,
              <ProjectCard key={index} {...item} />,
            )}
          </Grid.Col>
        </>
      ))}
    </Grid>
  );
};
