import { PrivateRoute } from "components/_private/PrivateRoute";
import { accountRoutes } from "modules/projectRole/router/routes/account";
import { projectCardRoutes } from "modules/projectRole/router/routes/card";
import { registriesRoutes } from "modules/projectRole/router/routes/registries";
import { profileRoutes } from "router/routers/PrivateRouter/routes/profile";
import { AnyObject } from "shared/types/common";
import { Route } from "shared/types/routes";
import { projectRoleMainRoute } from "./routes/main";
import { projectRoleNewProjectRoute } from "./routes/newProject";
import { projectInfoRoute } from "./routes/projectInfo";
import { projectRoleSettingsRoute } from "./routes/projectSettings";
import { projectRoleRoundsRoute } from "./routes/rounds";

export const projectRoleRoutes: AnyObject<Route> = {
  projectRoleRoutes: {
    path: "/project",
    Element: () => <PrivateRoute type="project" />,
    children: {
      ...projectRoleMainRoute,
      ...projectRoleNewProjectRoute,
      ...projectRoleSettingsRoute,
      ...projectRoleRoundsRoute,
      ...profileRoutes,
      ...projectInfoRoute,
      ...projectCardRoutes,
      ...registriesRoutes,
      ...accountRoutes,
    },
  },
};
