export const USER = {
  accounts: {
    individual: "ФЛ",
    entrepreneur: "ИП",
    legalEntity: "ЮЛ",
  },
  accountsFullNames: {
    individual: "Физическое лицо",
    entrepreneur: "Индивидуальное предпринимательство",
    legalEntity: "Юридическое лицо",
  },
};
