import {
  StatusVerifyEmail,
  VerifyEmailCheckData,
  verifyEmailFx,
  VerifyEmailSendProps,
} from "shared/api/verifyEmail";
import { FxSlice } from "shared/types/store";
import { shallow } from "zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";
import { createSliceNew, SliceModern } from "../_helpers/factories/createSliceNew";

type Fxs = {
  send: FxSlice<VerifyEmailSendProps>;
  check: FxSlice<VerifyEmailCheckData>;
};

const verifyEmailSlice = createSliceNew<StatusVerifyEmail, Fxs>({
  key: "verifyEmail",
  fxs: verifyEmailFx,
});

type UseEmail = {
  verifyEmail: SliceModern<StatusVerifyEmail, Fxs>;
};

export const useEmail = createWithEqualityFn<UseEmail>(
  (set, get) => ({
    verifyEmail: verifyEmailSlice(set, get),
  }),
  shallow,
);
