import React from "react";
import { useAccountChangesConnect } from "shared/hooks/_sockets/connets/useAccountChangesConnect";
import { io } from "socket.io-client";
import { useSessionID } from "../_auth/useSessionID";

//ПЕРЕДЕЛАТЬ СОКЕТЫ НА ПРОКСИ ПОТОМ
const VERCEL = "vercel";
const hostName = window.location.hostname.includes(VERCEL) ? "https://api.alfa.directual.com/" : "";

export const socket = io(hostName, { autoConnect: true });

export const useSocketsNotifications = () => {
  const [_, setIsConnected] = React.useState(socket.connected);
  const sessionID = useSessionID()!;

  useAccountChangesConnect();

  React.useEffect(() => {
    fetch("/crud/appid").then((response) => {
      response.json().then((data) => {
        socket.auth = { app_id: data.APP_ID, session_id: sessionID };
        socket.connect();
      });
    });

    socket.on("connect", () => {
      setIsConnected(true);
    });

    socket.on("disconnect", () => {
      setIsConnected(false);
    });

    // socket.on("connect_error", (err) => {
    //   // the reason of the error, for example "xhr poll error"
    //   console.log(err.message);
    //
    //   // some additional description, for example the status code of the initial HTTP response
    //   console.log(err.description);
    //
    //   // some additional context, for example the XMLHttpRequest object
    //   console.log(err.context);
    // });

    return () => {
      socket.off("connect");
      socket.off("disconnect");
    };
  }, []);
};
