export const REQUISITES = {
  general: {
    name: {
      name: "Название",
      value: 'ООО "Капитализация"',
    },
    inn: {
      name: "ИНН",
      value: "9722009697",
    },
    kpp: {
      name: "КПП",
      value: "772201001",
    },
    account: {
      name: "Номер счета",
      value: "40701810400000007862",
    },
    ogrn: {
      name: "ОГРН",
      value: "1217700498399",
    },
    bankName: {
      name: "Название банка",
      value: "АО «ТБанк»",
    },
    bankInn: {
      name: "ИНН Банка",
      value: "7710140679",
    },
    biq: {
      name: "БИК",
      value: "044525974",
    },
    corrAccount: {
      name: "Корр. Счет банка",
      value: "30101810145250000974",
    },
    legalAddress: {
      name: "Юридический адрес",
      value:
        "111024, РОССИЯ, Г.МОСКВА, ВН.ТЕР.Г. МУНИЦИПАЛЬНЫЙ ОКРУГ ЛЕФОРТОВО, УЛ АВИАМОТОРНАЯ, Д. 50, СТР. 2, ЭТАЖ 2, ПОМЕЩ./ОФИС XI/136",
    },
  },
  getValue: () =>
    Object.keys(REQUISITES.general).map((key) => ({
      [key]: (REQUISITES.general as any)[key].value,
    })),
  getArr: () =>
    Object.keys(REQUISITES.general).map((key) => ({
      name: (REQUISITES.general as any)[key].name,
      value: (REQUISITES.general as any)[key].value,
    })),
};
