import { DefaultGetItemsParams, Fx, OnlySidParams, SIDParams } from "shared/types/api";
import { Project, ProjectStatuses } from "../../types/projects";
import { createApi } from "../_factories/createApi";

const structure = "projects";
const api = createApi({ structure });

export type GetAllProjectsTypeParams = {
  statuses?: string;
  categories?: string;
} & DefaultGetItemsParams;

export type GetAllProjectsType = {
  params: GetAllProjectsTypeParams;
};

const PAGE_SIZE = 30;
const getAll: Fx<GetAllProjectsType, Project[], Error> = async ({ params }) =>
  await api().getItems<Project[]>("getAll", { pageSize: PAGE_SIZE, ...params });

export type GetByIdParams = {
  params: {
    id: string;
    status?: ProjectStatuses;
  } & SIDParams;
};

export type CreateProjectRequest = {
  params: SIDParams;
  data: {
    accountNumber: string;
    bankInn: string;
    birthPlace: string;
    birthday: string;
    charterDoc: string;
    dateOfIssue: string;
    decisionToAppointCEODoc: string;
    divisionCode: string;
    email: string;
    extractFromRegisterOfContractsDoc: string;
    firstName: string;
    id: string;
    issuedBy: string;
    lastName: string;
    location: string;
    middleName: string;
    name: string;
    noCriminalRecordDoc: string;
    number: string;
    // inn: string;
    // ogrn: string;
    // registrationDate: string;
    // legalAddress: string;
    // legalForm: string;
    // legalName: string;
    ownershipStructureDoc: string;
    passportsCopiesDocs: string;
    phone: string;
    photoFirstPage: string;
    photoSecondPage: string;
    platformFormDoc: string;
    powerToAttorneyDoc: string;
    recordOfCreationLegalEntityDoc: string;
    registrationAddress: string;
    serial: string;
    userId: string;
    website: string;
  };
};

export type CreateProjectResponse = {
  id: string;
  errorMessage: string;
  sendSMSId: string;
  status: string;
  userId: string;
  projectAgreement?: string;
};

export type CheckDuplicatesRequest = {
  params: SIDParams;
  data: {
    projectId: string;
  };
};

export type CheckDuplicatesResponse = {
  isExist: boolean;
};

const getById: Fx<GetByIdParams, Project, Error> = async ({ params }) =>
  await api().getItem<Project>("getById", params);

const getByOwner: Fx<OnlySidParams, Project, Error> = async ({ params }) =>
  api().getItem<Project>("getByOwner", params);

const create: Fx<CreateProjectRequest, CreateProjectResponse, Error> = async ({ params, data }) =>
  await api("createproject").setItem<any>("create", data, params);

const checkDuplicates: Fx<CheckDuplicatesRequest, CheckDuplicatesResponse, Error> = async ({
  data,
  params,
}) => await api("projectexist").setItem<CheckDuplicatesResponse>("check", data, params);

export const projectsFx = {
  getAll,
  getById,
  create,
  getByOwner,
  checkDuplicates,
};
