import { MantineSize } from "@mantine/core";
import { DatePickerInput as DatePickerMantine, DatesRangeValue } from "@mantine/dates";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { DatePickerRange as DatePickerRangeType } from "shared/types/forms";

type Props = Omit<DatePickerRangeType, "type"> & {
  size?: MantineSize;
};

export const DatePickerRange = ({
  names,
  name,
  placeholder,
  maxDate,
  minDate,
  error,
  label,
  size,
  required,
  disabled,
}: Props) => {
  const [startDateName, endDateName] = names.map((dateName) => `${name}.${dateName}`);
  const { control, setValue } = useFormContext();
  const formDates = useWatch({ control, name: [startDateName, endDateName] });
  const defaultDates = React.useMemo(
    () =>
      formDates.map((date) => {
        const validDate = Boolean(date && dayjs(date).isValid());
        return validDate ? dayjs(date).toDate() : null;
      }),
    [formDates],
  ) as DatesRangeValue;
  const [dates, setDates] = useState<DatesRangeValue>([null, null]);
  const dateProps = { placeholder, maxDate, minDate, error, label, size, required, disabled };

  React.useEffect(() => {
    if (!defaultDates[0] || !defaultDates[1]) return;
    if (dates[0] || dates[1]) return;

    setDates(defaultDates);
  }, [defaultDates]);

  const handleSetPeriod = (dates: DatesRangeValue) => {
    setDates(dates as DatesRangeValue);

    if (!dates.find((date) => date)) return;

    const formatDates = dates.map((date) => dayjs(date).format());
    setValue(startDateName, formatDates[0]);
    setValue(endDateName, formatDates[1]);
  };

  return (
    <DatePickerMantine
      {...dateProps}
      disabled={disabled}
      valueFormat="DD MMM YYYY"
      type={"range"}
      value={dates}
      onChange={handleSetPeriod}
    />
  );
};
