import { PrivateRoute } from "components/_private/PrivateRoute";
import { UserRole } from "shared/const/userRole";
import { AnyObject } from "shared/types/common";
import { Route } from "shared/types/routes";
import { accountsRoutes } from "./routes/accounts";
import { dealsRoutes } from "./routes/deals";
import { mainRoute } from "./routes/main";

export const investorRoleRoutes: AnyObject<Route> = {
  investorRoleRoutes: {
    Element: () => <PrivateRoute type={UserRole.investor} />,
    children: {
      ...mainRoute,
      ...accountsRoutes,
      // ...projectRoutes,
      ...dealsRoutes,
    },
  },
};
