import { DocFieldProfile } from "packages/profileFields/types";
import { useUserDocuments } from "shared/hooks/_user/useUserDocuments";
import { UserDocument } from "shared/types/user";

export const useConfig = () => {
  const { data, pending } = useUserDocuments();

  if (!data || data.length === 0) {
    return { pending };
  }

  const mapper = (doc: UserDocument): DocFieldProfile => ({
    type: "doc",
    label: doc.name,
    text: "Скачать документ",
    href: doc.file,
  });

  const fields = data.map(mapper);

  return { fields, pending };
};
