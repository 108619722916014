import { Box } from "@mantine/core";
import { Suspense } from "react";
import { Outlet, useLocation, useSearchParams } from "react-router-dom";
import { ROUTES } from "shared/const/ROUTES";
import { UserRole } from "shared/const/userRole";
import { useNavigateTo } from "shared/hooks/useNavigateTo";
import { useScrollTopWindow } from "shared/hooks/useSсrollTopWindow";
import { useUser } from "shared/hooks/_user/useUser";
import { CenterContainer } from "shared/ui/_containers/CenterContainer";
import { MainLoader } from "shared/ui/_loaders/MainLoader";
import { useSocketsNotifications } from "../../../shared/hooks/_sockets/useSocketsNotifications";
import { Footer } from "./Footer";
import styles from "./index.module.scss";
// import { useSocketsNotifications } from "shared/_hooks/_sockets/useSocketsNotifications";

export const MainLayout = () => {
  const location = useLocation();
  const searchParams = useSearchParams();
  const user = useUser();
  const path = user?.role?.includes(UserRole.project) ? ROUTES.PROJECT.MAIN : ROUTES.INVESTOR.MAIN;

  useSocketsNotifications();

  useScrollTopWindow({
    dependencies: [location.pathname, searchParams],
    condition: !location?.state?.withoutScrollTop,
  });

  useNavigateTo({
    path,
    dependencies: [],
    condition: location.pathname === "/",
  });

  return (
    <>
      {/*<NavbarSimple />*/}
      <Box component={"main"} className={styles.wrapper}>
        <Suspense
          fallback={
            <CenterContainer w={400}>
              <MainLoader />
            </CenterContainer>
          }
        >
          <Outlet />
        </Suspense>
      </Box>
      <Footer />
    </>
  );
};
