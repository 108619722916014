import { Box, LoadingOverlay, Paper } from "@mantine/core";
import { CheckSMSForm } from "pages/_service/CheckSMSPage/components/CheckSMSRouter/variants/CheckSMSCheck/CheckSMSForm";
import BootstrapGrid from "shared/ui/BootstrapGrid";

export const CheckSMSCheck = ({ loading = false }: { loading?: boolean }) => {
  return (
    <BootstrapGrid.Row>
      <BootstrapGrid.Item without={"Pb"} pos={"relative"} component={Box} mt={40} mb={40} col={4}>
        <Paper p={40} bg={"gray.0"}>
          <CheckSMSForm />
        </Paper>
        <LoadingOverlay
          visible={loading}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 3 }}
          loaderProps={{ type: "bars" }}
        />
      </BootstrapGrid.Item>
    </BootstrapGrid.Row>
  );
};
