import Api from "directual-api";
import { CONST } from "shared/const";
import { AnyObject } from "../../types/common";

const TYPE_OF_API = "front_lk";
const API_HOST = CONST.API.apiHost;

type CreateApi = {
  structure: string;
  version?: number;
  apiHost?: string;
};

export const createApi_DEPRICATED = (props: CreateApi) => {
  const defaultValues = { version: 1, apiHost: API_HOST };
  const { structure, version, apiHost } = Object.assign(defaultValues, props);

  const api = new Api({ apiHost });
  const getApiName = (apiName: string, currentStructure: string) =>
    `v${version}_${TYPE_OF_API}_${currentStructure}_${apiName}`;

  const createApiMethod = (specificStructure?: string) => {
    const currentStructure = specificStructure || structure;
    const getData = <Params>(apiName: string, params?: Params) =>
      api.structure(currentStructure).getData(getApiName(apiName, currentStructure), params as any);

    const setData = <Data, Params>(apiName: string, data: Data, params?: Params) =>
      api
        .structure(currentStructure)
        .setData(getApiName(apiName, currentStructure), data as any, params as any);

    const logout = (sessionID: string) => api.auth.logout(sessionID);

    return { getData, setData, auth: { logout } };
  };

  return createApiMethod;
};

type ParamsAndData = AnyObject<any>;

export const createApi = (props: CreateApi) => {
  const defaultValues = { version: 1, apiHost: API_HOST };
  const { structure, apiHost, version } = Object.assign(defaultValues, props);

  const api = new Api({ apiHost });

  const getApiName = (apiName: string, currentStructure: string) =>
    `v${version}_${TYPE_OF_API}_${currentStructure}_${apiName}`;
  const createApiMethod = (specificStructure?: string) => {
    const currentStructure = specificStructure || structure;
    const getItem = async <Payload>(apiName: string, params?: ParamsAndData) => {
      const response = await api
        .structure(currentStructure)
        .getData(getApiName(apiName, currentStructure), params as any);
      return response.payload[0] as Payload;
    };

    const getItems = async <Payload>(apiName: string, params?: ParamsAndData) => {
      const response = await api
        .structure(currentStructure)
        .getData(getApiName(apiName, currentStructure), params as any);
      return response.payload as Payload;
    };

    const setItem = async <Payload>(
      apiName: string,
      data: ParamsAndData,
      params?: ParamsAndData,
    ) => {
      const response = await api
        .structure(currentStructure)
        .setData(getApiName(apiName, currentStructure), data as any, params as any);
      return response.result[0] as Payload;
    };

    const setItems = async <Payload>(
      apiName: string,
      data: ParamsAndData,
      params?: ParamsAndData,
    ) => {
      const response = await api
        .structure(currentStructure)
        .setData(getApiName(apiName, currentStructure), data as any, params as any);
      return response.result as Payload;
    };

    const logout = (sessionID: string) => api.auth.logout(sessionID);

    return { getItem, getItems, setItem, setItems, auth: { logout } };
  };

  return createApiMethod;
};
