import { Accordion, Group, Stack } from "@mantine/core";
import { AccordionFieldProfile } from "packages/profileFields/types";
import { FC } from "react";
import { BadgeStatus } from "shared/ui/BadgeStatus";
import { ProfileFields } from "..";

export const AccordionFieldComp: FC<AccordionFieldProfile> = (props) => {
  const itemsComps = props.items?.map((item) => {
    return (
      <Accordion.Item key={item.label} value={item.label}>
        <Accordion.Control>
          <Group gap={"xs"}>
            {item.label}
            <Group gap={"xs"}>
              {item.badges.map((badge, i) => (
                <BadgeStatus {...badge} key={i} />
              ))}
            </Group>
          </Group>
        </Accordion.Control>
        <Accordion.Panel>
          <Stack gap={"sm"} mx={"sm"}>
            <ProfileFields fields={item.fields} />
          </Stack>
        </Accordion.Panel>
      </Accordion.Item>
    );
  });

  return <Accordion variant="separated">{itemsComps}</Accordion>;
};
