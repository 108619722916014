import { GetAllProjectsType, projectsFx } from "shared/api/_projects/projects";
import { Project } from "shared/types/projects";
import { FxSlice } from "shared/types/store";
import { createSliceNew } from "stores/_helpers/factories/createSliceNew";

export type AllProjectsSliceFxs = {
  fetchGet: FxSlice<GetAllProjectsType>;
};

export const allProjectsSlice = createSliceNew<Project[], AllProjectsSliceFxs>({
  key: "allProjects",
  fxs: { fetchGet: projectsFx.getAll },
});
