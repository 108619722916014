import { IconMailCheck, IconMailOff } from "@tabler/icons-react";
import { PageView } from "components/_common/_page/PageView";
import { VerifyEmailLayout } from "components/_layouts/VerifyEmailLayout";
import React from "react";
import { useSearchParams } from "shared/hooks/useSearchParams";
import { useEmail } from "stores/useEmail";

type VerifyEmailPageParams = {
  id: string;
  redirectTo: string;
  userKey: string;
};

const VerifyEmailPage = () => {
  const verifyEmail = useEmail(({ verifyEmail: { data, check } }) => ({
    data,
    check,
  }));
  const searchParams = useSearchParams().current as VerifyEmailPageParams;
  const verifyEmailCheck = verifyEmail.check;

  React.useEffect(() => {
    const { id, userKey } = searchParams;

    verifyEmailCheck.fx({
      id,
      userKey: userKey,
    });
  }, []);

  if (verifyEmailCheck.error) {
    return (
      <VerifyEmailLayout
        icon={<IconMailOff color={"red"} size={200} />}
        description={"Проверьте письмо, которое мы вам отправили"}
        title={"Почта не верифицирована"}
      />
    );
  }

  return (
    <PageView
      data={verifyEmail.data}
      pending={verifyEmailCheck.pending}
      error={verifyEmailCheck.error}
      errorComponent={() => (
        <VerifyEmailLayout
          icon={<IconMailOff color={"red"} size={200} />}
          description={"Проверьте письмо, которое мы вам отправили"}
          title={"Почта не верифицирована"}
        />
      )}
    >
      {() => (
        <VerifyEmailLayout
          icon={<IconMailCheck size={200} />}
          description={"Поздравляем! Теперь вы можете создавать счета"}
          title={"Почта верифицирована"}
          button={{
            path: searchParams.redirectTo || "/",
            title: "Перейти к следующему шагу",
          }}
        />
      )}
    </PageView>
  );
};

export default VerifyEmailPage;
