import React from "react";
import { Route } from "react-router-dom";
import { AnyObject } from "shared/types/common";
import { Route as RouteType } from "shared/types/routes";

export const CreateRoute = (routes: AnyObject<RouteType>) => {
  return (
    <React.Fragment>
      {Object.keys(routes).map((key) => {
        const { Element, children, path, errorElement } = routes[key];
        if (children) {
          return (
            <Route
              key={key}
              path={path}
              element={Element ? <Element /> : undefined}
              errorElement={errorElement}
            >
              {CreateRoute(children)}
            </Route>
          );
        }
        return <Route key={key} path={path} element={Element ? <Element /> : undefined} />;
      })}
    </React.Fragment>
  );
};
