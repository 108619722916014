import { Button, Container, Group, Text, Title } from "@mantine/core";
import React from "react";
import { Link } from "shared/ui/_links/Link";
import styles from "./index.module.scss";

type Props = {
  icon: React.ReactNode;
  title: string;
  description: string;
  button?: {
    path: string;
    title: string;
  };
};

export function VerifyEmailLayout({ icon, title, description, button }: Props) {
  return (
    <Container className={styles.root}>
      <div className={styles.label}>{icon}</div>
      <Title className={styles.title}>{title}</Title>
      <Text c="dimmed" size="lg" ta="center" className={styles.description}>
        {description}
      </Text>
      <Group justify="center">
        <Link to={button ? button.path : "/"}>
          <Button size="md">{button ? button.title : "Вернуться на главную"}</Button>
        </Link>
      </Group>
    </Container>
  );
}
