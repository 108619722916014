import { Fx, SIDParams } from "shared/types/api";
import { Passport, PassportResponseData } from "../../types/account";
import { createApi } from "../_factories/createApi";

const structure = "scanpassport";
const api = createApi({ structure });

export type ScanPassportProps = {
  data: {
    passportPhotoFirstPage: string;
    passportPhotoSecondPage: string;
    userId: string;
  };
  params: {
    sessionID: string;
  };
};

export type ScanPassportData = {
  id: string;
  status: string;
  errorMessage?: string;
} & Passport;

const scan: Fx<ScanPassportProps, ScanPassportData, Error> = async ({ data, params }) =>
  await api().setItem<ScanPassportData>("scan", data, params);

export type GetPassportParams = {
  sessionID: string;
  userId: string;
};

export type CheckDuplicatesRequest = {
  params: SIDParams;
  data: {
    number: string;
    serial: string;
  };
};

export type CheckDuplicatesResponse = {
  isExist: boolean;
};

const getByUserId: Fx<{ params: GetPassportParams }, PassportResponseData, Error> = async ({
  params,
}) => await api("passports").getItem<PassportResponseData>("getByUser", params);

const checkDuplicates: Fx<CheckDuplicatesRequest, CheckDuplicatesResponse, Error> = async ({
  data,
  params,
}) => await api("checkpassportdublicates").setItem<CheckDuplicatesResponse>("check", data, params);

export const passportFx = { scan, getByUserId, checkDuplicates };
