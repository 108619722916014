import {
  Badge,
  Button,
  Checkbox,
  Container,
  createTheme,
  CSSVariablesResolver,
  Divider,
  Drawer,
  Flex,
  Group,
  InputBase,
  Paper,
  Radio,
  rem,
  Select,
  Skeleton,
  Stack,
  Stepper,
  Textarea,
  TextInput,
  Title,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { Notifications } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons-react";
const CONTAINER_SIZES: Record<string, string> = {
  xxs: rem(300),
  xs: rem(400),
  sm: rem(500),
  md: rem(600),
  lg: rem(700),
  xl: rem(1100),
  xxl: rem(1344),
};

export const THEME = {
  defaultRadius: "md",
};

export const theme = createTheme({
  primaryColor: "blue",
  colors: {
    blue: [
      "#e6f1ff",
      "#cddfff",
      "#9abdff",
      "#6398ff",
      "#3678ff",
      "#1865ff",
      "#005bff",
      "#004be5",
      "#0043cd",
      "#0038b6",
    ],
    gray: [
      "#f3f4f7",
      "#e5e6e7",
      "#c7cacf",
      "#a7adb8",
      "#8c94a4",
      "#7a8499",
      "#717c94",
      "#5f6a81",
      "#545e74",
      "#455168",
    ],
    white: [
      "#f5f5f5",
      "#e7e7e7",
      "#cdcdcd",
      "#b2b2b2",
      "#9a9a9a",
      "#8b8b8b",
      "#848484",
      "#717171",
      "#656565",
      "#575757",
    ],
    gold: [
      "#fff8e1",
      "#ffefcc",
      "#ffdd9b",
      "#ffca64",
      "#ffba38",
      "#ffb01b",
      "#ffab09",
      "#e39500",
      "#ca8500",
      "#af7100",
    ],
    error: [
      "#ffe9e9",
      "#ffd4d4",
      "#f4a8a8",
      "#ec7879",
      "#e55050",
      "#e13636",
      "#e02728",
      "#c61a1d",
      "#b21217",
      "#9c0412",
    ],
  },

  fontFamily: "Inter",
  defaultRadius: THEME.defaultRadius,

  components: {
    Paper: Paper.extend({
      defaultProps: {
        shadow: "none",
      },
      styles: {
        root: {
          padding: "var(--mantine-spacing-lg)",
        },
      },
    }),
    TextInput: TextInput.extend({
      defaultProps: {
        variant: "filled",
      },
    }),
    Select: Select.extend({
      defaultProps: {
        variant: "filled",
      },
    }),
    DatePickerInput: DatePickerInput.extend({
      defaultProps: {
        variant: "filled",
      },
      styles: {
        label: {
          paddingBottom: "0.5em",
        },
      },
    }),
    Skeleton: Skeleton.extend({
      defaultProps: {
        // radius: "lg",
      },
    }),
    Stack: Stack.extend({
      defaultProps: {
        gap: "lg",
      },
    }),
    Group: Group.extend({
      defaultProps: {
        gap: "lg",
      },
    }),
    Checkbox: Checkbox.extend({
      defaultProps: {
        iconColor: "white",
      },
      styles: {
        root: {
          cursor: "pointer",
        },
        body: {
          alignItems: "center",
        },
      },
    }),
    Stepper: Stepper.extend({
      defaultProps: {
        completedIcon: <IconCheck color={"black"} />,
      },
    }),
    Radio: Radio.extend({
      defaultProps: {
        iconColor: "white.9",
      },
      styles: {
        body: {
          alignItems: "center",
        },
      },
    }),
    Flex: Flex.extend({
      defaultProps: {
        gap: "lg",
      },
    }),
    Drawer: Drawer.extend({
      defaultProps: {
        position: "right",
      },
      styles: {
        content: {
          padding: 0,
        },
      },
    }),
    Badge: Badge.extend({
      defaultProps: {
        c: "white",
      },
    }),
    Title: Title.extend({
      defaultProps: {
        fw: 500,
      },
    }),
    Container: Container.extend({
      vars: (_, { size, fluid }) => ({
        root: {
          "--container-size": fluid
            ? "100%"
            : size !== undefined && size in CONTAINER_SIZES
            ? CONTAINER_SIZES[size]
            : rem(size),
        },
      }),
    }),
    Button: Button.extend({
      defaultProps: {
        radius: "md",
        variant: "filled",
      },
    }),
    Divider: Divider.extend({
      defaultProps: {
        color: "gray.1",
      },
    }),
    Textarea: Textarea.extend({
      defaultProps: {
        variant: "filled",
      },
    }),
    InputBase: InputBase.extend({
      defaultProps: {
        variant: "filled",
      },
    }),
  },

  other: {
    beige: "#EBDFD5",
    brown: "#42221A",
    beigeLight: "#DAD0A7",
    typography: {
      success: "#1E5945",
    },
    margin: {
      main: "2rem",
      min: "1rem",
    },
  },
});

export const resolver: CSSVariablesResolver = (theme) => ({
  variables: {
    "--mantine-color-beige": theme.other.beige,
    "--mantine-color-beige-light": theme.other.beigeLight,
    "--mantine-color-brown": theme.other.brown,
    "--mantine-margin-element": "1.5rem",
    "--mantine-margin-main": theme.other.margin.main,
    "--mantine-margin-min": theme.other.margin.min,
    "--mantine-header-height": "4rem",
    "--mantine-footer-height": "3.5rem",
  },
  light: {},
  dark: {},
});

export const mobileTheme = createTheme({
  components: {
    ...theme.components,
    Notifications: Notifications.extend({
      defaultProps: {
        position: "top-center",
      },
    }),
  },
});
