export const ROUTES = {
  NOT_AUTH: {
    PARENT: "auth",
    CHECK_SMS: "/check-sms/",
    SIGN_UP: {
      PATH: "auth/sign-up",
      SMS_VERIFY: {
        PATH: "/auth/sign-in/sms-verify",
      },
    },
    SIGN_IN: {
      PATH: "auth/sign-in",
      SMS_VERIFY: {
        PATH: "/auth/sign-in/sms-verify",
      },
    },
  },
  STEP_FORMS: {
    CREATE_PROJECT: "/project/create",
    CREATE_ACCOUNT: {
      REL: "/accounts/:id/create/",
      INDIVIDUAL: "/accounts/individual/create/",
      ENTREPRENEUR: "/accounts/entrepreneur/create/",
      LEGAL_ENTITY: "/accounts/legalEntity/create/",
    },
  },
  INVESTOR: {
    MAIN: "/main",
    DEALS: {
      ROOT: "/deals",
      CREATE: {
        ROOT: "/deals/create",
        CURRENT_PROJECT: (projectId: string) => `/deals/create/${projectId}`,
      },
    },
  },
  PROJECT: {
    MAIN: "/project/main/",
    PROJECT_SETTINGS: "/project/settings/*",
    ACCOUNT: {
      ROOT: "/project/account",
    },
    CARD: {
      path: "/project/CardSettings",
      SETTINGS: {
        PATH: "/project/CardSettings/settings/*",
        COMMON: "/project/CardSettings/settings/common",
        MEDIA: "/project/CardSettings/settings/media",
        TEAM: "/project/CardSettings/settings/team",
      },
      PREVIEW: "/project/CardSettings/preview",
    },
    PROJECT_INFO: "/project/information",
    ROUNDS: {
      ALL_ROUNDS: "/project/rounds",
      CREATE_ROUND: "/project/rounds/create",
      EDIT_ROUND: "/project/rounds/edit",
      OFFERS: {
        ALL_OFFERS: (roundId: string) => `/project/rounds/${roundId}/offers`,
        CREATE_OFFER: (roundId: string) => `/project/rounds/${roundId}/offers/create`,
      },
    },
    REGISTRIES: {
      ROOT: "/project/registries",
    },
    PROFILE: {
      PATH: "/project/profile",
      COMMON: "/project/profile/common",
    },
  },
};
