import React from "react";

type UseScrollTop = (trigger?: any) => React.RefObject<HTMLDivElement>;

export const useScrollTop: UseScrollTop = (trigger) => {
  const scrollRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (!scrollRef?.current) {
      return;
    }

    scrollRef.current.scrollTo({
      top: 0,
    });
  }, [trigger, scrollRef]);

  return scrollRef;
};
