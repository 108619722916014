import { Button, Flex, Stack, Text, Title } from "@mantine/core";

interface ErrorFallbackProps {
  errorCode?: number;
}

export const ErrorFallback: React.FC<ErrorFallbackProps> = ({ errorCode }) => {
  const getErrorMessage = () => {
    switch (errorCode) {
      case 503:
        return "Сайт временно недоступен. Ведутся технические работы.";
      case 404:
        return "Страница не найдена.";
      default:
        return "Упс... Что-то пошло не так.";
    }
  };

  return (
    <Flex w="100%" justify="center">
      <Stack h="100%" justify="center" align="center" py={40}>
        <Title order={3}>{getErrorMessage()}</Title>
        <Text size="md">Попробуйте обновить страницу.</Text>
        <Button onClick={() => window.location.reload()}>Обновить</Button>
      </Stack>
    </Flex>
  );
};
