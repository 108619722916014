const PROJECT_STATUSES = {
  created: {
    label: "Создан",
    color: "gold",
    value: "created",
  },
  inPreparation: {
    label: "Готовится к размещению",
    color: "gold",
    value: "inPreparation",
  },
  fundraising: {
    label: "Раунд открыт",
    color: "black",
    value: "fundraising",
  },
  preparingNextOffer: {
    label: "Подготовка нового инвестпредложения",
    color: "gray",
    value: "preparingNextOffer",
  },
  roundComplete: {
    label: "Раунд закрыт",
    color: "grey",
    value: "roundComplete",
  },
};

const PROJECT_CATEGORIES = ["Стартап", "Малый и средний бизнес", "Pre-Ipo"];

const CREATE_ROUND_STATUSES = [
  "checkSMS",
  "verify",
  "offerTemplateVerify",
  "offerTemplateReject",
  "noVerified",
  "success",
] as const;

const CREATE_OFFER_STATUSES = [
  "checkSMS",
  "verify",
  "offerTemplateVerify",
  "offerTemplateReject",
  "noVerified",
  "success",
] as const;

const CREATE_ROUND_DOCS = {
  dsur: {
    label: "ДСУР",
    value: "dsur",
  },
  presentation: {
    label: "Презентация",
    value: "presentation",
  },
  financialModel: {
    label: "Финансовая модель",
    value: "financialModel",
  },
  otherDocs: {
    label: "Остальные документы",
    value: "otherDocs",
  },
  offerTemplate: {
    label: "Шаблон инвестиционного предложения",
    value: "offerTemplate",
  },
} as const;

const { offerTemplate, otherDocs, ...ROUND_DOCS } = CREATE_ROUND_DOCS;

export {
  CREATE_ROUND_STATUSES,
  CREATE_OFFER_STATUSES,
  PROJECT_STATUSES,
  PROJECT_CATEGORIES,
  CREATE_ROUND_DOCS,
  ROUND_DOCS,
};
