import { ActionIcon, Button, Popover, Stack, Text } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import { FC } from "react";
import { Link } from "react-router-dom";

export const EditProfilePopover: FC = () => {
  return (
    <Popover width={300}>
      <Popover.Target>
        <ActionIcon variant="transparent">
          <IconEdit />
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown>
        <Stack gap={"sm"}>
          <Text style={{ textWrap: "balance" }}>
            Для того что бы изменить данные, вам нужно отправить заявку в поддержку
          </Text>
          <Button w={"100%"} component={Link} to={"/support"}>
            Перейти
          </Button>
        </Stack>
      </Popover.Dropdown>
    </Popover>
  );
};
