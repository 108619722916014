import { Anchor, AnchorProps } from "@mantine/core";
import React from "react";
import { Link as LinkRoute } from "react-router-dom";
import { Sizes } from "shared/types/mantine";

type Props = {
  to: string;
  children: React.ReactNode | React.ReactNode[];
  param?: string;
  size?: Sizes;
  c?: string;
  className?: string;
  target?: string;
};

export const Link = ({ target, children, to, param, ...otherProps }: Props & AnchorProps) => (
  <Anchor
    target={target}
    component={LinkRoute}
    to={to + (param ? "/" + param : "")}
    {...otherProps}
  >
    {children}
  </Anchor>
);
