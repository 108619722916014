import { FC } from "react";
import { AccordionFieldComp } from "../components/AccordionFieldComp";
import { CollapsedFieldComp } from "../components/CollapsedFieldComp";
import { DocFieldComp } from "../components/DocFieldComp";
import { EmailFieldComp } from "../components/EmailFieldComp";
import { PhoneFieldComp } from "../components/PhoneFieldComp";
import { TextFieldComp } from "../components/TextFieldComp";
import { FieldProfileType } from "../types";

export const connectObject: Record<FieldProfileType, FC<any>> = {
  text: TextFieldComp,
  email: EmailFieldComp,
  phone: PhoneFieldComp,
  doc: DocFieldComp,
  collapsed: CollapsedFieldComp,
  accordion: AccordionFieldComp,
};
