import "@mantine/carousel/styles.css";
import { MantineProvider } from "@mantine/core";
import "@mantine/core/styles.css";
import { DatesProvider } from "@mantine/dates";
import "@mantine/dates/styles.css";
import "@mantine/dropzone/styles.css";
import { ModalsProvider } from "@mantine/modals";
import { Notifications } from "@mantine/notifications";
import "@mantine/notifications/styles.css";
import "@mantine/tiptap/styles.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "dayjs/locale/ru";
import { HelmetProvider } from "react-helmet-async";
import { RouterProvider } from "react-router-dom";
import { zodErrorMap } from "shared/helpers/validation/zodErrorMap";
import { useMobile } from "shared/hooks/useMobile";
import { z } from "zod";
import { router } from "./router";
import { mobileTheme as mobileThemeMantine, resolver, theme } from "./shared/ui/theme";

const queryClient = new QueryClient();
z.setErrorMap(zodErrorMap);

function App() {
  const { isMobile } = useMobile();
  const mobileTheme = isMobile ? mobileThemeMantine : {};

  return (
    <HelmetProvider>
      <MantineProvider
        theme={{ ...theme, primaryShade: 6, ...mobileTheme }}
        cssVariablesResolver={resolver}
      >
        <Notifications />
        <ModalsProvider>
          <QueryClientProvider client={queryClient}>
            <DatesProvider settings={{ locale: "ru" }}>
              <RouterProvider router={router} />
            </DatesProvider>
          </QueryClientProvider>
        </ModalsProvider>
      </MantineProvider>
    </HelmetProvider>
  );
}

export default App;
