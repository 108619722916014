import { notifications } from "@mantine/notifications";
import { useState } from "react";
import { uploadFileFX } from "shared/api/files";
import { userFx } from "shared/api/user";
import { useCheckAuth } from "../_auth/useCheckAuth";
import { useSessionID } from "../_auth/useSessionID";

const { setAvatar, deleteAvatar } = userFx;

export const useUserAvatar = () => {
  const [deletePending, setDeletePending] = useState(false);
  const [uploadPending, setUploadPending] = useState(false);
  const sessionID = useSessionID();
  const {
    pending: pendingUser,
    data: userData,
    checkAuth: { set: setUser },
  } = useCheckAuth();

  const deleteUserAvatar = async () => {
    if (!userData) return;

    try {
      setDeletePending(true);
      await deleteAvatar({
        params: { sessionID: sessionID! },
        data: {
          id: userData.id,
        },
      });

      setUser({ data: { ...userData, userpic: undefined } });
    } catch (error) {
      notifications.show({
        title: "Удаление фото прошло неудачно",
        message: error instanceof Error ? error.message : "Не удалось удалить фото",
        color: "red",
      });
    } finally {
      setDeletePending(false);
    }
  };

  const updateUserAvatar = async (file: File) => {
    if (!userData) return;

    try {
      setUploadPending(true);
      const fileUrl = await uploadFileFX({
        sessionID: sessionID!,
        file,
      });
      const { userpic } = await setAvatar({
        params: { sessionID: sessionID! },
        data: { id: userData.id, userpic: fileUrl },
      });

      setUser({ data: { ...userData, userpic } });
    } catch (error) {
      notifications.show({
        title: "Загрузка фото прошла неудачно",
        message: error instanceof Error ? error.message : "Не удалось загрузить фото",
        color: "red",
      });
    } finally {
      setUploadPending(false);
    }
  };

  return {
    deleteUserAvatar,
    updateUserAvatar,
    deletePending,
    uploadPending,
    pending: uploadPending || deletePending || pendingUser,
  };
};
