import { Link } from "react-router-dom";
import logo from "shared/assets/images/logo.svg";
import { UserRole } from "shared/const/userRole";
import { useUser } from "shared/hooks/_user/useUser";

type Props = {
  alt?: string;
  width?: number;
  height?: number;
};

export const MainLogo = (props: Props) => {
  const user = useUser();
  const to = user ? (user.role.includes(UserRole.investor) ? "/main" : "/project/main") : "/";

  return (
    <Link to={to}>
      <img src={logo} {...props} />
    </Link>
  );
};
