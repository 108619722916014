import { userDocumentsFx } from "shared/api/_user/userDocuments";
import { SIDParams } from "shared/types/api";
import { FxSlice } from "shared/types/store";
import { UserDocument } from "shared/types/user";
import { createSimpleStore } from "stores/_helpers/factories/createSimpleStore";

type Fxs = { fetchGet: FxSlice<SIDParams> };

export const useUserDocuments = createSimpleStore<UserDocument[], Fxs>({
  fetchGet: userDocumentsFx.get,
});
