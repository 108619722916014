import moment from "moment";
import { CONST } from "../const";
import { toArray } from "./toArray";

const { standardDate } = CONST.REGEX;

export const toRuDate = (date: string | number): string | undefined => {
  if (typeof date === "number") return moment(date).format("DD.MM.YYYY");
  if (standardDate.test(date)) {
    return toArray(date, "-").reverse().join(".");
  } else {
    console.error("Invalid date. Date must be match /^\\d{4}-\\d{2}-\\d{2}$/");
  }
};
