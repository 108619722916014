import { rem, Stack, Tabs, Title } from "@mantine/core";
import { useToggle } from "@mantine/hooks";
import { ProjectCards } from "components/project/ProjectCards";
import { PageContainer } from "components/_common/_page/PageContainer";
import { PageView } from "components/_common/_page/PageView";
import { BreadCrumbsPageBlock } from "components/_common/_page/_blocks/BreadCrumbsPageBlock";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useMobile } from "shared/hooks/useMobile";
import { useAllProjects } from "shared/hooks/_projects/useAllProducts";
import BootstrapGrid from "shared/ui/BootstrapGrid";

const breadCrumbsPageBlock = [{ title: "Главная", href: "/" }, { title: "Проекты" }];

export enum ProjectTab {
  ActiveRound = "active-round",
  RoundComplete = "round-complete",
}

export const projectTabMap = {
  [ProjectTab.ActiveRound]: {
    title: "Раунд открыт",
    statuses: ["fundraising", "preparingNextOffer"],
  },
  [ProjectTab.RoundComplete]: {
    title: "Раунд закрыт",
    statuses: ["roundComplete"],
  },
};

export const ProjectsPage = () => {
  const { setMobileOption, setDesktopOption } = useMobile();
  const [value, toggle] = useToggle(
    Object.values(projectTabMap).map(({ statuses }) => statuses.join(",")),
  );
  const [hasNotActiveRounds, toggleHasNotActiveRounds] = useToggle();

  const {
    data: projectsData,
    pending: projectsPending,
    error: projectsError,
  } = useAllProjects({
    customParams: {
      statuses: value,
    },
  });

  useEffect(() => {
    if (projectsData?.length === 0 && projectTabMap[ProjectTab.ActiveRound].statuses.join(",")) {
      toggle("roundComplete");
      toggleHasNotActiveRounds(true);
    }
  }, [projectsData]);

  return (
    <>
      <Helmet>
        <title>Проекты</title>
        <meta name="description" content="Запуск - Проекты платформы." />
        <meta property="og:title" content="Zapusk - Projects" />
        <meta property="og:description" content="Zapusk - Platform projects" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <PageView data={projectsData} pending={projectsPending} error={projectsError}>
        {(projectsData) => (
          <PageContainer component={"section"} id={"project-section"}>
            <BreadCrumbsPageBlock items={breadCrumbsPageBlock} />
            <BootstrapGrid.Row component={Stack}>
              <BootstrapGrid.Item without={"Pb"}>
                <Title
                  component={"h1"}
                  order={2}
                  fw={setMobileOption(500)}
                  size={setMobileOption(rem(30))}
                >
                  Проекты
                </Title>
              </BootstrapGrid.Item>
              <BootstrapGrid.Item without={"Pb"}>
                <Tabs
                  value={value}
                  onChange={(val) => val && toggle(val)}
                  defaultValue={projectTabMap[ProjectTab.ActiveRound].statuses.join(",")}
                  w={setDesktopOption("fit-content")}
                >
                  <Tabs.List grow={setMobileOption(true)}>
                    <Tabs.Tab
                      disabled={hasNotActiveRounds}
                      p={"10px 20px"}
                      value={projectTabMap[ProjectTab.ActiveRound].statuses.join(",")}
                    >
                      {projectTabMap[ProjectTab.ActiveRound].title}
                    </Tabs.Tab>
                    <Tabs.Tab
                      p={"10px 20px"}
                      value={projectTabMap[ProjectTab.RoundComplete].statuses.join(",")}
                    >
                      {projectTabMap[ProjectTab.RoundComplete].title}
                    </Tabs.Tab>
                  </Tabs.List>
                </Tabs>
              </BootstrapGrid.Item>
              <BootstrapGrid.Item without={"Pb"}>
                <ProjectCards projects={projectsData} />
              </BootstrapGrid.Item>
            </BootstrapGrid.Row>
          </PageContainer>
        )}
      </PageView>
    </>
  );
};
