import { getInitials } from "../../helpers/getInitials";
import { useCheckAuth } from "../_auth/useCheckAuth";

export const useUser = () => {
  const user = useCheckAuth(true).data;
  if (!user) return null;
  const { lastName, firstName, middleName } = user;
  const fullName = [lastName, firstName, middleName].reduce(
    (fullName, part) => fullName + (part ? " " + part : ""),
    "",
  );
  const project = user?.projectLink;
  const initials = getInitials(firstName, lastName);
  const role = user.role;

  return {
    fullName,
    initials,
    project,
    ...user,
  };
};
