import { TextInput } from "@mantine/core";
import { IMaskMixin } from "react-imask";

import styles from "./index.module.scss";

export const MaskStyledInput: any = IMaskMixin(({ inputRef, ...props }: any) => {
  return (
    <TextInput {...props} variant={"filled"} ref={inputRef} className={styles.maskStyledInput} />
  );
});
