import { Stack } from "@mantine/core";
import { ProjectKeyFactorsBlock } from "components/project/_blocks/ProjectKeyFactorsBlock";
import { AccessChecker } from "components/_access/AccessChecker";
import { InnerMarkdown } from "components/_common/InnerMarkdown";
import { LimitedAccessComponent } from "components/_common/LimitedAccessComponent";
import { useMobile } from "shared/hooks/useMobile";
import { useWindowScrollToPosition } from "shared/hooks/useWindowScrollToPosition";
import { useProject } from "shared/hooks/_projects/useProject";

export const ProjectAboutPage = () => {
  const { setOption } = useMobile();
  const ref = useWindowScrollToPosition(setOption(-150, -200));
  const project = useProject({ withoutEffect: true });

  if (!project.data) return null;

  const html = project.data.aboutProject;
  return (
    <Stack ref={ref}>
      {/*<ProjectReasonsToInvestBlock />*/}
      <AccessChecker rejectChildren={<LimitedAccessComponent />}>
        <ProjectKeyFactorsBlock />
        <InnerMarkdown>{html || ""}</InnerMarkdown>
      </AccessChecker>
    </Stack>
  );
};
