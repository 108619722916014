import { createApi } from "shared/api/_factories/createApi";
import { DefaultGetItemParams, Fx } from "shared/types/api";
import { ActualProjectDocuments } from "shared/types/projects";

const structure = "projectdocuments";
const api = createApi({ structure });

export type GetActualProjectDocumentsParams = {
  projectId: string;
} & DefaultGetItemParams;

export type GetActualProjectDocumentsData = ActualProjectDocuments;

const getActualProjectDocuments: Fx<
  GetActualProjectDocumentsParams,
  GetActualProjectDocumentsData,
  Error
> = async (params: GetActualProjectDocumentsParams) => await api().getItem("getActive", params);

export const projectDocumentsFxs = { getActualProjectDocuments };
