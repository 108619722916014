import { notifications } from "@mantine/notifications";
import { IconMailCheck } from "@tabler/icons-react";
import { useEmail } from "stores/useEmail";
import { useSessionID } from "../_auth/useSessionID";
import { useUser } from "./useUser";

export const useVerifyEmail = () => {
  const verifyEmail = useEmail(({ verifyEmail }) => verifyEmail);

  const userId = useUser()?.id;
  const sessionID = useSessionID();

  const handleVerifyEmail = async () => {
    if (!userId) return;

    const props = {
      params: { sessionID: sessionID! },
      data: { userId },
    };
    try {
      await verifyEmail.send.fx(props);

      notifications.show({
        title: "Письмо с проверкой ушло на почту",
        message: "Следуйте инструкции из письма, чтобы закончить верификацию",
        color: "green",
        autoClose: 5000,
        withBorder: true,
        icon: <IconMailCheck />,
      });
    } catch (err) {
      notifications.show({
        title: "Письмо не отправлено",
        message: "Попробуйте еще раз",
        color: "red",
        autoClose: 5000,
        withBorder: true,
        icon: <IconMailCheck />,
      });
    }
  };

  return { handleVerifyEmail, pending: verifyEmail.send.pending };
};
