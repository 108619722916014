import { InputBase as InputBaseComp } from "@mantine/core";
import dayjs from "dayjs";
import { Controller, FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import { toStandardDate } from "shared/helpers/toStandardDate";
import { Sizes } from "shared/types/mantine";

import styles from "./index.module.scss";

const InputBase = InputBaseComp as any;

type Props = {
  name: string;
  control: any;
  size?: Sizes;
  label?: string;
  required?: boolean;
  dateStartName?: string;
  error?: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
  minDate?: Date;
  maxDate?: Date;
  placeholder?: string;
  disabled?: boolean;
};

const rules = (required?: boolean) => ({
  required: required
    ? {
        value: true,
        message: "Поле обязательно для заполнения",
      }
    : {},
  pattern: {},
});

export const DateInput = ({
  name,
  control,
  size,
  required,
  label,
  error,
  dateStartName,
  maxDate,
  minDate,
  placeholder,
  disabled,
}: Props) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={""}
      rules={rules(required) as any}
      render={({ field: { onChange, onBlur, value } }) => {
        const handleChange = (event: any) => {
          if (!event.target.value) {
            onChange(event);
            return null;
          }
          const date = event.target.value.split("-");
          const dd = date[2];
          const mm = date[1];
          const year = date[0].slice(0, 4);
          const newDate = `${year}-${mm}-${dd}`;
          const newEvent = { ...event, target: { ...event.target, value: newDate } };
          onChange(newEvent);
        };

        const minDateFormat = minDate && dayjs(minDate).format("YYYY-MM-DD");
        const maxDateFormat = maxDate && dayjs(maxDate).format("YYYY-MM-DD");

        return (
          <InputBase
            className={styles.inputBase}
            size={size}
            variant={"filled"}
            type={"date"}
            label={label}
            value={toStandardDate(value)}
            min={minDateFormat}
            max={maxDateFormat}
            required={required}
            placeholder={placeholder}
            error={error as any}
            onChange={handleChange}
            onBlur={onBlur}
            disabled={disabled}
          />
        );
      }}
    />
  );
};
