import {
  Box,
  Burger,
  Button,
  Center,
  Container,
  Divider,
  Drawer,
  Flex,
  Group,
  List,
  Menu,
  Stack,
  useMantineTheme,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import {
  IconBrush,
  IconBusinessplan,
  IconCoins,
  IconDoorExit,
  IconHome,
  IconUserCircle,
  IconWallet,
} from "@tabler/icons-react";
import { AccessChecker } from "components/_access/AccessChecker";
import { Avatar } from "components/_common/Avatar";
import React, { useRef } from "react";
import { useLocation } from "react-router-dom";
import { ROUTES } from "shared/const/ROUTES";
import { UserRole } from "shared/const/userRole";
import { useLogout } from "shared/hooks/useLogout";
import { useMobile } from "shared/hooks/useMobile";
import BootstrapGrid from "shared/ui/BootstrapGrid";
import { Link } from "shared/ui/_links/Link";
import { MainLogo } from "shared/ui/_logos/MainLogo";

const MENU = [
  { label: "Проекты", to: "/projects" },
  // { label: "Калькулятор", to: "/landing" },
  { label: "Документы", to: "/platform-docs" },
  { label: "Блог", to: "/blog" },
];

const AUTH_MENU_INVESTOR = [
  { label: "Личный кабинет", to: "/main", Icon: IconHome },
  { label: "Счета", to: "/accounts", Icon: IconWallet },
  { label: "Сделки", to: "/deals", Icon: IconCoins },
  { label: "Настройки профиля", to: "/profile/common", Icon: IconUserCircle },
] as const;

const AUTH_MENU_PROJECT = [
  { to: ROUTES.PROJECT.MAIN, label: "Личный кабинет", Icon: IconHome },
  { to: ROUTES.PROJECT.ACCOUNT.ROOT, label: "Счет", Icon: IconWallet },
  { to: ROUTES.PROJECT.ROUNDS.ALL_ROUNDS, label: "Раунды", Icon: IconBusinessplan },
  { to: ROUTES.PROJECT.CARD.SETTINGS.COMMON, label: "Настройки карточки", Icon: IconBrush },
  { to: ROUTES.PROJECT.PROFILE.COMMON, label: "Профиль", Icon: IconUserCircle },
] as const;

const Z_INDEX = 199;

export const Header = () => {
  const { isMobile } = useMobile();
  const [openedMobileMenu, { open: openMobileMenu, close: closeMobileMenu }] = useDisclosure();
  const paddings = ["30px 16px", "20px 16px"];
  const location = useLocation();
  const logout = useLogout();
  const [isWindowScrolled, setIsWindowScrolled] = React.useState(false);
  const theme = useMantineTheme();
  const topBarRef = useRef<null | HTMLDivElement>(null);

  React.useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      if (scrollTop > 0) {
        setIsWindowScrolled(true);
      } else {
        setIsWindowScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const isActiveLink = (to: string, contains: boolean = true) => {
    if (!contains) {
      return location.pathname === to;
    }

    return location.pathname.includes(to);
  };

  React.useEffect(() => {
    closeMobileMenu();
  }, [location.pathname]);

  const toggleMobileMenu = () => {
    if (!openedMobileMenu) {
      if (topBarRef.current && window.scrollY < topBarRef.current.offsetHeight) {
        window.scroll({ top: topBarRef.current.offsetHeight, behavior: "smooth" });
      }
      openMobileMenu();
      return;
    }
    closeMobileMenu();
  };

  if (isMobile)
    return (
      <>
        {/*<TopBar topBarRef={topBarRef} />*/}
        <Box component={"header"} pos={"sticky"} top={0} style={{ zIndex: Z_INDEX }} bg={"gray.0"}>
          <Container size={"xxl"} p={paddings[1]}>
            <BootstrapGrid.Row>
              <BootstrapGrid.Item
                component={Group}
                align={"center"}
                justify={"space-between"}
                without={"Pb"}
                withoutSpacing
              >
                <MainLogo width={100} />
                <Burger
                  opened={openedMobileMenu}
                  onClick={toggleMobileMenu}
                  aria-label="Toggle navigation"
                />
                <Drawer
                  bg={"gray.1"}
                  position="left"
                  title={<MainLogo width={100} />}
                  opened={openedMobileMenu}
                  onClose={closeMobileMenu}
                >
                  <Stack>
                    <Divider />
                    <List component={Stack}>
                      <Link
                        fw={500}
                        variant={"transparent"}
                        c={isActiveLink("/", false) ? "primary" : "black"}
                        size={"xl"}
                        p={0}
                        to={"/"}
                      >
                        Главная
                      </Link>
                      {MENU.map(({ label, to }, index) => (
                        <Link
                          fw={500}
                          variant={"transparent"}
                          c={isActiveLink(to) ? "primary" : "black"}
                          size={"xl"}
                          p={0}
                          key={index}
                          to={to}
                        >
                          {label}
                        </Link>
                      ))}
                    </List>
                    <Divider />
                    <AccessChecker role={[UserRole.investor]}>
                      {AUTH_MENU_INVESTOR.map(({ label, to }, index) => (
                        <Link
                          fw={500}
                          variant={"transparent"}
                          c={isActiveLink(to) ? "primary" : "black"}
                          size={"xl"}
                          p={0}
                          key={index}
                          to={to}
                        >
                          {label}
                        </Link>
                      ))}
                    </AccessChecker>
                    <AccessChecker role={[UserRole.project]}>
                      {AUTH_MENU_INVESTOR.map(({ label, to }, index) => (
                        <Link
                          fw={500}
                          variant={"transparent"}
                          c={isActiveLink(to) ? "primary" : "black"}
                          size={"xl"}
                          p={0}
                          key={index}
                          to={to}
                        >
                          {label}
                        </Link>
                      ))}
                    </AccessChecker>
                    <AccessChecker>
                      <Divider />
                      <Button variant={"default"} leftSection={<IconDoorExit />} onClick={logout}>
                        Выйти
                      </Button>
                    </AccessChecker>
                    <AccessChecker isAuthenticated={false}>
                      <Group justify={"left"}>
                        <Button
                          component={Link}
                          to={"/auth/sign-in"}
                          variant={"transparent"}
                          size={"lg"}
                          p={"2px 5px"}
                          mr={20}
                        >
                          Вход
                        </Button>
                        <Button
                          component={Link}
                          to={"/auth/sign-up"}
                          size={"lg"}
                          variant={"outline"}
                          p={"0 8px"}
                        >
                          Регистрация
                        </Button>
                      </Group>
                    </AccessChecker>
                  </Stack>
                </Drawer>
              </BootstrapGrid.Item>
            </BootstrapGrid.Row>
          </Container>
        </Box>
      </>
    );

  return (
    <>
      {/*<TopBar topBarRef={topBarRef} />*/}
      <Box
        component={"header"}
        pos={"sticky"}
        top={0}
        bg={"gray.0"}
        style={{
          zIndex: Z_INDEX,
          borderBottom: isWindowScrolled ? `1px solid ${theme.colors.gray[3]}` : "none",
        }}
      >
        <Container size={"xxl"} p={paddings[0]}>
          <BootstrapGrid.Row>
            <BootstrapGrid.Item
              col={4}
              component={Group}
              align={"center"}
              without={"Pb"}
              withoutSpacing
            >
              <List>
                <Group gap={"md"} wrap={"nowrap"} align={"center"}>
                  {MENU.map(({ label, to }, index) => (
                    <Link
                      fw={500}
                      variant={"transparent"}
                      c={isActiveLink(to) ? "primary" : "black"}
                      size={"md"}
                      p={0}
                      key={index}
                      to={to}
                    >
                      {label}
                    </Link>
                  ))}
                </Group>
              </List>
            </BootstrapGrid.Item>
            <BootstrapGrid.Item col={4} component={Center} withoutSpacing without={"Pb"}>
              <Center>
                <MainLogo width={120} />
              </Center>
            </BootstrapGrid.Item>
            <BootstrapGrid.Item col={4} without={"Pb"} withoutSpacing>
              <AccessChecker isAuthenticated={true}>
                <Flex justify={"end"} align={"center"}>
                  <AccessChecker role={[UserRole.investor]}>
                    <Button component={Link} to={"/deals/create"} leftSection={<IconCoins />}>
                      Инвестировать
                    </Button>
                  </AccessChecker>
                  <Menu>
                    <Menu.Target>
                      <div>
                        <Avatar component={"button"} size={"md"} />
                      </div>
                    </Menu.Target>
                    <Menu.Dropdown>
                      <AccessChecker role={[UserRole.investor]}>
                        {AUTH_MENU_INVESTOR.map(({ label, to, Icon }, index) => (
                          <Box key={index} mb={8}>
                            <Link to={to} style={{ textDecoration: "none" }}>
                              <Menu.Item
                                leftSection={<Icon />}
                                color={isActiveLink(to) ? "primary" : "black"}
                                key={index}
                              >
                                {label}
                              </Menu.Item>
                            </Link>
                          </Box>
                        ))}
                      </AccessChecker>
                      <AccessChecker role={[UserRole.project]}>
                        {AUTH_MENU_PROJECT.map(({ label, to, Icon }, index) => (
                          <Box key={index} mb={8}>
                            <Link to={to} style={{ textDecoration: "none" }}>
                              <Menu.Item
                                leftSection={<Icon />}
                                color={isActiveLink(to) ? "primary" : "black"}
                                key={index}
                              >
                                {label}
                              </Menu.Item>
                            </Link>
                          </Box>
                        ))}
                      </AccessChecker>
                      <Menu.Item leftSection={<IconDoorExit />} onClick={logout}>
                        Выйти
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                </Flex>
              </AccessChecker>
              <AccessChecker isAuthenticated={false}>
                <Group justify={"right"}>
                  <Button
                    component={Link}
                    to={"/auth/sign-in"}
                    variant={"transparent"}
                    size={"sm"}
                    p={"2px 5px"}
                  >
                    Вход
                  </Button>
                  <Button
                    component={Link}
                    to={"/auth/sign-up"}
                    variant={"outline"}
                    size={"sm"}
                    p={"0 8px"}
                  >
                    Регистрация
                  </Button>
                </Group>
              </AccessChecker>
            </BootstrapGrid.Item>
          </BootstrapGrid.Row>
        </Container>
      </Box>
    </>
  );
};
