import { MantineSize, Text } from "@mantine/core";
import { toRuDate } from "shared/helpers/toRuDate";
import { DateBody } from "shared/types/table";

export type TableDateProps = {
  body: DateBody;
  size: MantineSize | string | undefined;
};
export const TableDate = ({ size, body }: TableDateProps) => {
  return (
    <Text
      fw={400}
      size={size}
      style={{
        textWrap: "nowrap",
      }}
    >
      {toRuDate(body.value)}
    </Text>
  );
};
