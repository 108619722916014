import { Text } from "@mantine/core";
import { FC } from "react";

interface TextFieldCompProps {
  text: string;
}

export const TextFieldComp: FC<TextFieldCompProps> = ({ text }) => {
  return <Text>{text}</Text>;
};
