import { Stack } from "@mantine/core";
import { ProjectDocsBlock } from "components/project/_blocks/ProjectDocBlock";
import { AccessChecker } from "components/_access/AccessChecker";
import { LimitedAccessComponent } from "components/_common/LimitedAccessComponent";
import { PageView } from "components/_common/_page/PageView";
import { useParams } from "react-router-dom";
import { useMobile } from "shared/hooks/useMobile";
import { useWindowScrollToPosition } from "shared/hooks/useWindowScrollToPosition";
import { useActualProjectDocuments } from "shared/hooks/_projects/useActualProjectDocuments";

export const ProjectDocsPage = () => {
  const { setOption } = useMobile();
  const { projectId = "" } = useParams();
  const ref = useWindowScrollToPosition(setOption(-150, -200));
  const { data, pending, error } = useActualProjectDocuments({ projectId });

  return (
    <PageView data={data} pending={pending} error={error}>
      {(actualProjectDocuments) => (
        <Stack ref={ref}>
          <AccessChecker rejectChildren={<LimitedAccessComponent />}>
            <ProjectDocsBlock actualProjectDocuments={actualProjectDocuments} />
          </AccessChecker>
        </Stack>
      )}
    </PageView>
  );
};
