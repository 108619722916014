import { Container, ContainerProps } from "@mantine/core";

import React from "react";
import { useMobile } from "shared/hooks/useMobile";
import { Sizes } from "shared/types/mantine";
import styles from "./index.module.scss";

type Props = {
  children: React.ReactNode | React.ReactNode[];
  fixedHeightFrom?: Sizes | string | number;
  p?: string | number;
  h?: string | number;
} & ContainerProps & {
    component?: any;
  };

export const PageContainer = ({
  children,
  fixedHeightFrom,
  h = "base",
  ...containerProps
}: Props) => {
  const { setOption } = useMobile();
  const fixedHeightValue = fixedHeightFrom ? { [fixedHeightFrom]: "90vh" } : {};

  return (
    <Container
      className={styles.container}
      pt={setOption(40, 50)}
      pb={setOption(40, 50)}
      size={"xxl"}
      h={{ base: h, ...fixedHeightValue }}
      {...containerProps}
    >
      {children}
    </Container>
  );
};
