import {
  teamMembersFx,
  TeamMembersGetByIdsParams,
  TeamMembersGetByProjectParams,
  TeamMembersSetItemsProps,
} from "shared/api/_projects/teamMembers";
import { TeamMember } from "shared/types/projects";
import { FxSlice } from "shared/types/store";
import { createSimpleStore } from "stores/_helpers/factories/createSimpleStore";

type Fxs = {
  fetchGetByProject: FxSlice<TeamMembersGetByProjectParams>;
  fetchGetByIds: FxSlice<TeamMembersGetByIdsParams>;
  fetchSetItems: FxSlice<TeamMembersSetItemsProps>;
};

const { getByProject, getByIds, setItems } = teamMembersFx;
export const useTeamMembersStore = createSimpleStore<TeamMember[], Fxs>({
  fetchGetByProject: getByProject,
  fetchGetByIds: getByIds,
  fetchSetItems: setItems,
});
