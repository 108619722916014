import { Layout } from "components/_layouts/Layout";
import { AuthPageLazy } from "pages/_auth/AuthPage/lazy";
import React from "react";
import { AnyObject } from "shared/types/common";
import { Route } from "shared/types/routes";
import { CheckNoAuth } from "../../../components/_private/CheckNoAuth";

const SignInPage = React.lazy(() => import("pages/_auth/AuthPage/SignInPage"));
const SignUpPage = React.lazy(() => import("pages/_auth/AuthPage/SignUpPage"));

export const authRoutes: AnyObject<Route> = {
  checkNotAuth: {
    Element: CheckNoAuth,
    children: {
      main: {
        Element: Layout,
        children: {
          layout: {
            path: "auth",
            Element: AuthPageLazy,
            children: {
              signIn: {
                path: "sign-in",
                Element: SignInPage,
              },
              signUp: {
                path: "sign-up",
                Element: SignUpPage,
              },
            },
          },
        },
      },
    },
  },
};
