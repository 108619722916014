import { notifications } from "@mantine/notifications";
import React from "react";
import { AnyObject } from "shared/types/common";
import { FxSlice } from "shared/types/store";
import { SliceModern } from "stores/_helpers/factories/createSliceNew";
import { StoreApi } from "zustand";
import { UseBoundStoreWithEqualityFn } from "zustand/traditional";
import { useSessionID } from "../_auth/useSessionID";
import { useUser } from "../_user/useUser";

type CreateProps<Data, Props> = {
  useStore: UseBoundStoreWithEqualityFn<StoreApi<SliceModern<Data, { fetchGet: FxSlice<Props> }>>>;
};

type HookProps = {
  params?: AnyObject<string> | null;
  data?: AnyObject<any>;
  withUserId?: boolean;
  withoutEffect?: boolean;
};

export const createSimpleHookWithStore = <Data, Props>({ useStore }: CreateProps<Data, Props>) => {
  const useSimpleHookWithStore = ({
    withoutEffect = false,
    params = null,
    data = {},
    withUserId = false,
  }: HookProps | void = {}) => {
    const { data: storeData, fetchGet, reset } = useStore();
    const { pending, error, fx } = fetchGet;
    const sessionID = useSessionID()!;
    const userId = useUser()?.id;
    const conditionRequest = storeData === null && !pending && !withoutEffect && !error;
    const preparedParams = { sessionID, ...params };
    const preparedData = { ...data, ...(withUserId && userId ? { userId } : {}) };
    const preparedProps = { params: preparedParams, data: preparedData } as Props;

    React.useEffect(() => {
      if (conditionRequest) fx(preparedProps);
    }, [params]);

    React.useEffect(() => {
      const errorNotification = () => ({
        color: "red",
        title: "Произошла ошибка",
        message: error,
      });
      if (error && !withoutEffect) notifications.show(errorNotification());
    }, [error]);

    const handleRefresh = () => {
      !pending && fx(preparedProps);
    };

    return { data: storeData, pending, error, handleRefresh, reset };
  };

  return useSimpleHookWithStore;
};
