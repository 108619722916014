import { Stack } from "@mantine/core";
import { ProjectTeamBlock } from "components/project/_blocks/ProjectTeamBlock";
import { useMobile } from "shared/hooks/useMobile";
import { useWindowScrollToPosition } from "shared/hooks/useWindowScrollToPosition";

export const ProjectTeamPage = () => {
  const { setOption } = useMobile();
  const ref = useWindowScrollToPosition(setOption(-150, -200));
  return (
    <Stack ref={ref}>
      <ProjectTeamBlock />
    </Stack>
  );
};
