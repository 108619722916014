import { Box, NumberFormatter, Paper, Stack, Text, Title } from "@mantine/core";
import { DEFAULT_GRADIENT } from "shared/hooks/useGradient";
import { useProject } from "shared/hooks/_projects/useProject";

const keyFactors = [
  { label: "Инвестиции (общая сумма раунда):", value: "target" },
  { label: "В рамках текущего инвестиционного предложения:", value: "roundTarget" },
  { label: "Мин. сумма инвестиций:", value: "minInvestment" },
  { label: "Цена за 1 акцию:", value: "sharePrice" },
] as const;

export const ProjectKeyFactorsBlock = () => {
  const project = useProject({ withoutEffect: true });

  const round = {
    target: project.data?.activeRound.target,
    roundTarget: project.data?.activeRound.activeOffer.target,
    minInvestment: project.data?.activeRound.activeOffer.minAmount,
    sharePrice: project.data?.activeRound.activeOffer.sharePrice,
  };

  return (
    <Paper bg={"gray.0"}>
      <Stack align={"center"}>
        <Title ta={"center"} order={3}>
          Ключевые факты
        </Title>
        {keyFactors.map((factor) => (
          <Box key={factor.value}>
            <Text fz={18} ta={"center"}>
              {factor.label}
            </Text>
            <Text fz={30} variant={"gradient"} gradient={DEFAULT_GRADIENT} ta={"center"}>
              <NumberFormatter thousandSeparator={" "} suffix={" ₽"} value={round[factor.value]} />
            </Text>
          </Box>
        ))}
      </Stack>
    </Paper>
  );
};
