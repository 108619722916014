import { Fx, SIDParams } from "shared/types/api";
import { UserDocument } from "shared/types/user";
import { createApi } from "../_factories/createApi";

const structure = "userdocuments";
const api = createApi({ structure });

type GetProps = {
  params: SIDParams;
};

const get: Fx<GetProps, UserDocument[], Error> = async ({ params }) =>
  await api().getItems<UserDocument[]>("getAll", params);

export const userDocumentsFx = { get };
