import { Button, Flex, Group, Text } from "@mantine/core";
import { EmailFieldProfile } from "packages/profileFields/types";
import { FC } from "react";
import { BadgeStatus } from "shared/ui/BadgeStatus";

export const EmailFieldComp: FC<EmailFieldProfile> = ({ email, badgeStatus, button }) => {
  return (
    <Flex justify="space-between" direction={{ base: "column", xs: "row" }}>
      <Group gap={"xs"}>
        <Text>{email}</Text>
        <BadgeStatus status={badgeStatus} success="Верифицирован" warning="Не верифицирован" />
      </Group>
      {button.hidden || (
        <Button size="sm" onClick={button.onClick} loading={button.pending}>
          {button.text || "Верифицировать"}
        </Button>
      )}
    </Flex>
  );
};
