import { GetByIdParams, projectsFx } from "shared/api/_projects/projects";
import { Project } from "shared/types/projects";
import { FxSlice } from "shared/types/store";
import { createSliceNew } from "stores/_helpers/factories/createSliceNew";

export type ProjectSliceFxs = {
  fetchGet: FxSlice<GetByIdParams>;
};

export const projectSlice = createSliceNew<Project, ProjectSliceFxs>({
  key: "project",
  fxs: { fetchGet: projectsFx.getById },
});
