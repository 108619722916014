import { useDeals as useDealsStore } from "modules/investorRole/stores/deals/useDeals";
import React from "react";
import { useParams } from "react-router-dom";
import { useSessionID } from "shared/hooks/_auth/useSessionID";
import { AnyObject } from "shared/types/common";

type Props = { condition?: boolean };

const defaultProps = { condition: true };

export const useDeals = ({ condition }: Props = defaultProps) => {
  const { data, ...deals } = useDealsStore(({ deals }) => deals);
  const [page] = React.useState(0);
  const sessionID = useSessionID()!;
  const paramsStatus = useParams().status!;
  const { pending, error, fx } = deals.fetchGet;
  const statuses: AnyObject<string> = {
    wait: "notPaid,notSignature,wait",
    complete: "complete",
    cancel: "cancel",
  };
  const fxParams = { sessionID, page, status: statuses[paramsStatus] as string, pageSize: 30 };

  React.useEffect(() => {
    if (!pending && condition) fx(fxParams);
  }, [paramsStatus]);

  return { data, pending, error, refresh: () => fx(fxParams) };
};
