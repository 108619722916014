import { IconExternalLink } from "@tabler/icons-react";
import { Deal } from "shared/types/deals";
import { BodyTypes, CreateRow } from "shared/types/table";

type Config = {
  titles: string[];
  dealRow: CreateRow<Deal>;
};
export const filterDealsConfig: Config = {
  titles: ["", "Дата создания", "Счет", "Проект", "Сумма", "Кол-во акций", "Статус"],
  dealRow: ({ data, onClick }) => ({
    button: {
      value: <IconExternalLink />,
      type: BodyTypes.actionIcon,
      onClick: () => onClick && onClick?.(data.id),
    },
    date: {
      value: data.dateAndTimeSMSSignature,
      type: BodyTypes.date,
    },
    account: {
      value: data.accountId.id || "",
      type: BodyTypes.text,
    },
    name: {
      value: data.projectName || "",
      type: BodyTypes.text,
    },
    amount: {
      value: data.amount,
      type: BodyTypes.price,
    },
    shares: {
      value: data.shares,
      type: BodyTypes.number,
    },
    status: {
      value: data.status.id,
      type: BodyTypes.status,
      variants: {
        notSignature: {
          color: "gold",
          label: "Не подписана",
          value: "notSignature",
        },
        wait: {
          color: "gold",
          label: "Сбор средств",
          value: "wait",
        },
        notPaid: {
          color: "gold",
          label: "Ожидает оплаты",
          value: "notPaid",
        },
        cancel: {
          color: "red",
          label: "Отменена",
          value: "cancel",
        },
        complete: {
          color: "green",
          label: "Завершена",
          value: "complete",
        },
      },
    },
  }),
};
