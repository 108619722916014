import { notifications } from "@mantine/notifications";
import { PrivateRouteProps, RouteKeys } from "components/_private/PrivateRoute/types";
import dayjs from "dayjs";
import { useLayoutEffect, useRef } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { ROUTES } from "shared/const/ROUTES";
import { UserRole } from "shared/const/userRole";
import { and } from "shared/helpers/and";
import { or } from "shared/helpers/or";
import { pickStringValue } from "shared/helpers/pickStringValue";
import { useUser } from "shared/hooks/_user/useUser";
import { CheckListInvestor, CheckListProject } from "shared/types/user";
import { checkAccess, Conditions } from "./helpers/checkAccess";

const exceptionUsers = ["79777777777"];
export function PrivateRoute({ type, backPath }: PrivateRouteProps) {
  const navigate = useNavigate();
  const user = useUser();

  const isProject = !!user?.role.includes(UserRole.project);
  const isInvestor = !!user?.role.includes(UserRole.investor);
  const investorCheckList = user!.checklist as CheckListInvestor;
  const projectCheckList = user!.checklist as CheckListProject;
  const roleKey = pickStringValue(isProject, ["INVESTOR", "PROJECT"]) as RouteKeys;
  const mainRoute = ROUTES[roleKey].MAIN;

  const conditions = {
    emailVerify: {
      value: user!.checklist.emailVerified,
      errorMessage: "Для начала подтвердите почту",
    },
    createAccount: {
      value: and(isInvestor, investorCheckList.createAccountResponse),
      errorMessage: "Для начала создайте счет",
    },
    accountVerified: {
      value: and(isInvestor, investorCheckList.createAccountVerified),
      errorMessage: "Дождитесь подтверждения счета",
    },
    project: {
      value: !!user?.role.includes(UserRole.project),
      errorMessage: "Этот раздел доступен только для фаундеров",
    },
    investor: {
      value: !!user?.role.includes(UserRole.investor),
      errorMessage: "Этот раздел доступен только для инвесторов",
    },
    rounds: {
      value: and(isProject, projectCheckList.projectCreated),
      errorMessage: "Этот раздел доступен после создания раунда",
    },
    cardSettings: {
      value: or(and(isProject, projectCheckList.roundCreated), exceptionUsers.includes(user!.id)),
      errorMessage: "Этот раздел доступен после создания раунда",
    },
    projectAccount: {
      value: or(and(isProject, projectCheckList.projectCreated), exceptionUsers.includes(user!.id)),
      errorMessage: "Этот раздел доступен только после создания компании",
    },
  } satisfies Conditions;

  const { hasAccess, errorMessage } = checkAccess(conditions, type);
  const navigationKey = useRef(dayjs().format()); // Уникальный ключ для отслеживания одной навигации

  useLayoutEffect(() => {
    if (!errorMessage) return;

    if (window.history.state && window.history.state.key === navigationKey.current) {
      return;
    }

    // Сохраняем ключ в историю
    window.history.replaceState({ ...window.history.state, key: navigationKey.current }, "");

    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      navigate(mainRoute, { replace: true });
    }

    notifications.show({
      id: "1",
      title: "Доступ запрещен",
      message: errorMessage,
      color: "red",
    });
  }, [errorMessage]);

  if (hasAccess) return <Outlet />;

  return null;
}
