import { Fx } from "shared/types/api";
import { createApi } from "./_factories/createApi";

const structure = "verifyemail";
const api = createApi({ structure });

type params = {
  sessionID: String;
};

type VerifyEmailSendData = {
  userId: string;
};

export type VerifyEmailSendProps = {
  params: params;
  data: VerifyEmailSendData;
};

export type StatusVerifyEmail = any;

const send: Fx<VerifyEmailSendProps, StatusVerifyEmail, Error> = async ({ params, data }) => {
  const fullData = { ...data, domain: window.location.origin };
  return await api().setItem<StatusVerifyEmail>("send", fullData, params);
};

export type VerifyEmailCheckData = {
  id: string;
  userKey: string;
};

const check: Fx<VerifyEmailCheckData, StatusVerifyEmail, Error> = async (data) =>
  await api().setItem<StatusVerifyEmail>("check", data);

export const verifyEmailFx = {
  send,
  check,
};
