import { Layout } from "components/_layouts/Layout";
import { CheckAuth } from "components/_private/CheckAuth";
import CheckSMSPage from "pages/_service/CheckSMSPage";
import ForbiddenPage from "pages/_service/ForbiddenPage";
import NotFoundPage from "pages/_service/NotFoundPage";
import VerifyEmailPage from "pages/_service/VerifyEmailPage";
import { LandingPageLazy } from "pages/_withoutAuth/LandingPage/lazy";
import { NewsPageLazy } from "pages/_withoutAuth/NewsPage/lazy";
import { NewPageLazy } from "pages/_withoutAuth/NewsPage/NewPage/lazy";
import { PlatformDocumentsPageLazy } from "pages/_withoutAuth/PlatformDocumentsPage/lazy";
import { ProjectsPage } from "pages/_withoutAuth/ProjectsPage";
import { ProjectPageLazy } from "pages/_withoutAuth/ProjectsPage/ProjectPage/lazy";
import { ProjectAboutPage } from "pages/_withoutAuth/ProjectsPage/ProjectPage/pages/ProjectAboutPage";
import { ProjectDocsPage } from "pages/_withoutAuth/ProjectsPage/ProjectPage/pages/ProjectDocsPage";
import { ProjectTeamPage } from "pages/_withoutAuth/ProjectsPage/ProjectPage/pages/ProjectTeamPage";
import { othersRoutes } from "router/routers/PrivateRouter/routes/othersRoutes";
import { AnyObject } from "shared/types/common";
import { Route } from "shared/types/routes";

export const commonRoutes: AnyObject<Route> = {
  checkAuth: {
    Element: () => <CheckAuth isPrivate={false} />,
    children: {
      withoutAuthLayout: {
        Element: Layout,
        children: {
          landing: {
            path: "/",
            Element: LandingPageLazy,
          },
          ...othersRoutes,
          projects: {
            path: "/projects",
            children: {
              page: {
                path: "",
                Element: ProjectsPage,
              },
              project: {
                Element: ProjectPageLazy,
                path: ":projectId/*", //TODO: Потом заменить на /project/*/**
                children: {
                  about: {
                    path: "about",
                    Element: ProjectAboutPage,
                  },
                  docs: {
                    path: "docs",
                    Element: ProjectDocsPage,
                  },
                  team: {
                    path: "team",
                    Element: ProjectTeamPage,
                  },
                },
              },
            },
          },
          news: {
            path: "/blog",
            Element: NewsPageLazy,
          },
          newItem: {
            path: "/blog/:id",
            Element: NewPageLazy,
          },
          platformDocuments: {
            path: "/platform-docs",
            Element: PlatformDocumentsPageLazy,
          },
          notFound: {
            path: "*",
            Element: NotFoundPage,
          },
          verifyEmail: {
            path: "/verify-email",
            Element: VerifyEmailPage,
          },
          checkSMS: {
            path: "/check-sms/",
            Element: CheckSMSPage,
          },
          forbidden: {
            path: "/forbidden",
            Element: ForbiddenPage,
          },
        },
      },
    },
  },
};
