import { Anchor, Group, Stack, Text } from "@mantine/core";
import {
  IconBriefcase,
  IconChartBar,
  IconFile,
  IconHeartRateMonitor,
  IconLicense,
  IconWritingSign,
} from "@tabler/icons-react";
import { ReactNode } from "react";
import { ActualProjectDocuments } from "shared/types/projects";

const DOCS: {
  label: string;
  icon: ReactNode;
  value: keyof ActualProjectDocuments;
}[] = [
  { label: "Презентация", icon: <IconHeartRateMonitor />, value: "presentation_round" },
  { label: "Финмодель", icon: <IconChartBar />, value: "financialModel_round" },
  { label: "Устав", icon: <IconLicense />, value: "charter_project" },
  { label: "ДСУР", icon: <IconFile />, value: "dsur_round" },
  {
    label: "Решение о выпуске ценных бумаг",
    icon: <IconWritingSign />,
    value: "addIssueDescription_round",
  },
  { label: "Инвестиционное предложение", icon: <IconBriefcase />, value: "investmentOffer_offer" },
];
export const ProjectDocsBlock = ({
  actualProjectDocuments,
}: {
  actualProjectDocuments: ActualProjectDocuments;
}) => {
  const docs = DOCS.map((doc, index) => {
    const docFile = actualProjectDocuments[doc.value];
    if (!docFile) {
      return null;
    }

    return (
      <Anchor key={index} target={"_blank"} href={docFile}>
        <Group gap={5}>
          {doc.icon}
          <Text>{doc.label}</Text>
        </Group>
      </Anchor>
    );
  });

  return <Stack>{docs}</Stack>;
};
