import { Box, Paper, Stack } from "@mantine/core";
import { BreadCrumbsPageBlock } from "components/_common/_page/_blocks/BreadCrumbsPageBlock";
import { ProfileFields } from "packages/profileFields/components";
import { ProfileLayout } from "pages/_private/ProfilePage/components/ProfileLayout";
import { Helmet } from "react-helmet-async";
import { useConfig } from "./config";

const breadCrumbsPageBlock = [{ title: "Главная", href: "/main" }, { title: "Счета" }];

export const ProfileAccountsPage = () => {
  const config = useConfig();
  return (
    <>
      <Helmet>
        <title>Счета</title>
        <meta name="description" content="Запуск - Счета." />
        <meta property="og:title" content="Zapusk - Accounts" />
        <meta property="og:description" content="Zapusk - Accounts" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Box pos="absolute" top="0">
        <BreadCrumbsPageBlock items={breadCrumbsPageBlock} isWhiteBc={false} />
      </Box>
      <ProfileLayout title={"Счета"}>
        <Paper component={Stack}>
          <ProfileFields {...config} withoutDivider />
        </Paper>
      </ProfileLayout>
    </>
  );
};
