import { SegmentedControl as SegmentedControlMantine } from "react-hook-form-mantine";
import { Data } from "shared/types/forms";
import styles from "./index.module.scss";

type Props = {
  name: string;
  data: Data[];
  defaultProps: string;
  control: any;
};

export const SegmentedControl = (props: Props) => {
  return <SegmentedControlMantine {...props} className={styles.wrapper} />;
};
