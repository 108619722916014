import { notifications } from "@mantine/notifications";
import React from "react";
import { useLocation } from "react-router-dom";
import { useProjects } from "stores/projects/useProjects";
import { useSearchParams } from "../useSearchParams";
import { useSessionID } from "../_auth/useSessionID";

export type UseAllProjectCustomParams = {
  statuses?: string;
  categories?: string;
  search?: string;
};

type Props = {
  withoutAutoRequest?: boolean;
  customParams?: UseAllProjectCustomParams | null;
};

const paramsKeys = ["categories", "badge", "search"];

const defaultProps = {
  customParams: {},
  withoutAutoRequest: false,
};

export const useAllProjects = ({ customParams, withoutAutoRequest }: Props = defaultProps) => {
  const changedCustomParams = customParams || {};
  const [page] = React.useState(0);
  const {
    data,
    fetchGet: { fx, pending, error },
    reset,
  } = useProjects(({ allProjects }) => allProjects);
  const sessionID = useSessionID()!;
  const searchParams = useSearchParams(paramsKeys).current;
  const location = useLocation();
  const requestProps = {
    params: { sessionID: sessionID!, ...searchParams, page, ...changedCustomParams },
  };
  const notification = {
    title: "Возникла ошибка",
    message: error,
    color: "red",
  };
  const getCondition = !pending && !withoutAutoRequest;

  const handleGet = () => !pending && fx(requestProps);

  React.useEffect(() => {
    if (getCondition) fx(requestProps);
  }, [searchParams, location.pathname, customParams?.statuses]);

  React.useEffect(() => {
    if (error) notifications.show(notification);
  }, [error]);

  return { data, pending, error, handleGet, refresh: () => fx(requestProps), reset };
};
