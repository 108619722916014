import { Image, TypographyStylesProvider } from "@mantine/core";
import { ClassAttributes, ImgHTMLAttributes } from "react";
import ReactMarkdown, { ExtraProps } from "react-markdown";
import rehypeRaw from "rehype-raw";

const ImageReactMarkdown = (
  imageData: ClassAttributes<HTMLImageElement> & ImgHTMLAttributes<HTMLImageElement> & ExtraProps,
) => {
  return <Image src={imageData.src}></Image>;
};

export const InnerMarkdown = ({ children }: { children: string }) => {
  return (
    <TypographyStylesProvider>
      <ReactMarkdown
        rehypePlugins={[rehypeRaw]}
        components={{
          img: ImageReactMarkdown,
        }}
      >
        {children}
      </ReactMarkdown>
    </TypographyStylesProvider>
  );
};
