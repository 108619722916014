import { Button, Stack } from "@mantine/core";
import { Avatar } from "components/_common/Avatar";
import { useRef } from "react";
import { CONST } from "shared/const";
import { useUser } from "shared/hooks/_user/useUser";
import { useUserAvatar } from "shared/hooks/_user/useUserAvatar";
import { FileButton } from "shared/ui/_buttons/FileButton";

export const ProfileAvatar = () => {
  const { updateUserAvatar, deleteUserAvatar, pending, uploadPending, deletePending } =
    useUserAvatar();
  const user = useUser();
  const resetRef = useRef<() => void>(null);

  const onChangeFile = (file: File | null) => {
    if (!file) return;

    updateUserAvatar(file);
  };

  const hasAvatar = !!user?.userpic;

  const handleDeleteUserAvatar = () => {
    deleteUserAvatar();
    resetRef.current?.();
  };

  return (
    <Stack>
      <Avatar size={"xl"} h={200} w={200} />
      <Stack gap={"xs"}>
        <FileButton
          onChange={onChangeFile}
          accept={"image/png,image/jpg,image/jpeg"}
          resetRef={resetRef}
          maxSize={CONST.VALUES.fileUploadMaxSize}
        >
          {(props) => (
            <Button {...props} loading={uploadPending} disabled={pending}>
              {hasAvatar ? "Изменить" : "Добавить"}
            </Button>
          )}
        </FileButton>
        {hasAvatar && (
          <Button
            loading={deletePending}
            disabled={pending}
            onClick={handleDeleteUserAvatar}
            variant={"default"}
          >
            Удалить
          </Button>
        )}
      </Stack>
    </Stack>
  );
};
