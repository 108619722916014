import { Box, Group, Paper, Title } from "@mantine/core";
import { EditProfilePopover } from "components/profile/EditProfilePopover";
import { ReactNode } from "react";
import BootstrapGrid from "shared/ui/BootstrapGrid";

export const ProfileLayout = ({ title, children }: { title: string; children: ReactNode }) => {
  return (
    <BootstrapGrid.Row>
      <BootstrapGrid.Item col={12}>
        <Paper>
          <Group justify="space-between">
            <Group gap={"sm"}>
              <Title order={2}>{title}</Title>
            </Group>
            <EditProfilePopover />
          </Group>
        </Paper>
      </BootstrapGrid.Item>
      <BootstrapGrid.Item col={12} without="Pb" component={Box}>
        {children}
      </BootstrapGrid.Item>
    </BootstrapGrid.Row>
  );
};
