import {
  GetActualProjectDocumentsParams,
  projectDocumentsFxs,
} from "shared/api/_projects/projectDocuments";
import { ActualProjectDocuments } from "shared/types/projects";
import { FxSlice } from "shared/types/store";
import { createSimpleStore } from "stores/_helpers/factories/createSimpleStore";

type Fxs = {
  fetchGetAll: FxSlice<GetActualProjectDocumentsParams>;
};

const { getActualProjectDocuments } = projectDocumentsFxs;

export const useActualProjectDocumentsStore = createSimpleStore<ActualProjectDocuments, Fxs>({
  fetchGetAll: getActualProjectDocuments,
});
