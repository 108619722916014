import { zodResolver } from "@hookform/resolvers/zod";
import { notifications } from "@mantine/notifications";
import { CheckSMSContext } from "pages/_service/CheckSMSPage";
import { CHECK_SMS_FORM_KEYS } from "pages/_service/CheckSMSPage/components/CheckSMSRouter/variants/CheckSMSCheck/CheckSMSForm/const";
import { CheckSMSFormType } from "pages/_service/CheckSMSPage/components/CheckSMSRouter/variants/CheckSMSCheck/CheckSMSForm/types";
import { checkSMSFormValidation } from "pages/_service/CheckSMSPage/components/CheckSMSRouter/variants/CheckSMSCheck/CheckSMSForm/validation";
import { UseCheckSMSReturn } from "pages/_service/CheckSMSPage/types";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import smsFx from "shared/api/sms";
import { ROUTES } from "shared/const/ROUTES";
import { useFetch } from "shared/hooks/useFetch";
import { useNavigateTo } from "shared/hooks/useNavigateTo";
import { useSendError } from "shared/hooks/useSendError";
import { useAuth } from "stores/useAuth";

export const useCheckSMSForm = () => {
  const { locationState } = useContext(CheckSMSContext) as UseCheckSMSReturn;
  const checkSMS = useFetch(smsFx.check);
  const resendSMS = useFetch(smsFx.resend);
  const { setAuth } = useAuth();
  const {
    control,
    handleSubmit: handleSubmitUseForm,
    formState: { errors },
  } = useForm<CheckSMSFormType>({
    resolver: zodResolver(checkSMSFormValidation),
    mode: "onChange",
  });

  const errorMessage = errors[CHECK_SMS_FORM_KEYS.smsCodeInvestor]?.message || null;
  const id = locationState.id as string;

  useSendError(errorMessage);
  useSendError(checkSMS.error);
  useSendError(resendSMS.error);

  useNavigateTo({
    condition: Boolean(checkSMS.data),
    dependencies: [checkSMS.data],
    path: locationState?.pathSuccess || ROUTES.INVESTOR.MAIN,
  });

  React.useEffect(() => {
    if (!locationState.notification || !checkSMS.data) return;

    notifications.show({
      id: "successSMS",
      title: locationState.notification.title,
      message: locationState.notification.message,
    });
  }, [checkSMS.data]);

  React.useEffect(() => {
    if (!checkSMS.data?.token) return;

    setAuth(checkSMS.data.token);
  }, [checkSMS.data]);

  const check = ({ smsCodeInvestor }: CheckSMSFormType) => {
    const data = {
      smsCodeInvestor,
      id,
    };

    checkSMS.fetchData(data);
  };

  const handleCheck = handleSubmitUseForm(check);
  const handleResend = () => {
    resendSMS.fetchData({ id });
  };

  return {
    phone: locationState.phone,
    checkSMS: { ...checkSMS, handleCheck, control },
    resendSMS: { ...resendSMS, handleResend },
  };
};
