import { Box, Paper, Stack } from "@mantine/core";
import { BreadCrumbsPageBlock } from "components/_common/_page/_blocks/BreadCrumbsPageBlock";
import { ProfileFields } from "packages/profileFields/components";
import { ProfileLayout } from "pages/_private/ProfilePage/components/ProfileLayout";
import { Helmet } from "react-helmet-async";
import { useConfig } from "./config";

const breadCrumbsPageBlock = [{ title: "Главная", href: "/main" }, { title: "Документы" }];

const ProfileDocsPage = () => {
  const config = useConfig();

  return (
    <>
      <Helmet>
        <title>Документы</title>
        <meta name="description" content="Запуск - Документы." />
        <meta property="og:title" content="Zapusk - Documents" />
        <meta property="og:description" content="Zapusk - Documents" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Box pos="absolute" top="0">
        <BreadCrumbsPageBlock items={breadCrumbsPageBlock} isWhiteBc={false} />
      </Box>
      <ProfileLayout title={"Документы"}>
        <Paper component={Stack}>
          <ProfileFields {...config} />
        </Paper>
      </ProfileLayout>
    </>
  );
};

export default ProfileDocsPage;
