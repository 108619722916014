import { Box, Flex, rem, Title } from "@mantine/core";
import { IconLockCancel } from "@tabler/icons-react";

type Size = keyof typeof SIZES;

type AuthOverlayProps = {
  size?: Size;
};

const SIZES = {
  sm: {
    fontSize: "12px",
    padding: "5px 0",
  },
  md: {
    fontSize: "14px",
    padding: "10px 0",
  },
};

export const AccessLimited = ({ size = "md" }: AuthOverlayProps) => {
  return (
    <Box style={{ position: "relative", display: "flex", justifyContent: "center" }}>
      <Box h="100%" w={rem("200px")}>
        <Flex gap={rem("8px")} p={rem(SIZES[size].padding)} align="center" justify="center">
          <IconLockCancel width={20} />
          <Title fw={500} size={rem(SIZES[size].fontSize)} lh={rem("20px")}>
            Доступ ограничен
          </Title>
        </Flex>
      </Box>
    </Box>
  );
};
