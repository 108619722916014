import { Avatar, Box, Group, Text } from "@mantine/core";
import { useProject } from "shared/hooks/_projects/useProject";

export const ProjectTeamBlock = () => {
  const project = useProject({ withoutEffect: true });

  if (!project.data) return null;

  const personCards = project.data.teamMembers?.map((person) => (
    <Box key={person.id}>
      <Group wrap={"nowrap"} gap={12} mb={10}>
        <Avatar size={80} src={person.photo} style={{ border: "2px solid #3678ff" }} />
        <Box w={"fit-content"}>
          <Text size={"16px"} mb={10} fw={500}>
            {person.firstName} {person.lastName}
          </Text>
          <Text size={"16px"}>{person.position}</Text>
        </Box>
      </Group>
    </Box>
  ));

  return <>{personCards}</>;
};
