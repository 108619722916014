import { LazyProfilePage } from "pages/_private/ProfilePage/lazy";
import { ProfileAccountsPage } from "pages/_private/ProfilePage/ProfileAccountsPage";
import ProfileCommonPage from "pages/_private/ProfilePage/ProfileCommonPage";
import ProfileDocsPage from "pages/_private/ProfilePage/ProfileDocsPage";
import { ProfilePassportPage } from "pages/_private/ProfilePage/ProfilePassportsPage";
import { AnyObject } from "shared/types/common";
import { Route } from "shared/types/routes";

export const profileRoutes: AnyObject<Route> = {
  profile: {
    Element: LazyProfilePage,
    path: "profile/*",
    children: {
      common: {
        Element: ProfileCommonPage,
        path: "common",
      },
      accounts: {
        Element: ProfileAccountsPage,
        path: "accounts",
      },
      docs: {
        Element: ProfileDocsPage,
        path: "docs",
      },
      passport: {
        Element: ProfilePassportPage,
        path: "passport",
      },
    },
  },
};
