import { passportFx } from "shared/api/_accounts/passport";
import { PassportResponseData } from "shared/types/account";
import { SIDParams } from "shared/types/api";
import { FxSlice } from "shared/types/store";
import { createSimpleStore } from "stores/_helpers/factories/createSimpleStore";

type Fxs = { fetchGet: FxSlice<SIDParams> };

const { getByUserId } = passportFx;

export const useUserPassport = createSimpleStore<PassportResponseData, Fxs>({
  fetchGet: getByUserId,
});
