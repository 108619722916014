import { AspectRatio, Center, Group, Paper, Stack, Text, Title } from "@mantine/core";
import { CARD_SETTINGS_NAMES } from "modules/projectRole/pages/CardSettingsPage/config";
import { FC } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { TextInput } from "react-hook-form-mantine";
import { REGEX } from "shared/const/regex";
import { getIframeYoutubeLink } from "shared/helpers/getIframeYoutubeLink";

export const VideoSection: FC = () => {
  const { control } = useFormContext();
  const videoUrl = useWatch({ control, name: CARD_SETTINGS_NAMES.video });
  const youtubeIframeLink = REGEX.youtube.test(videoUrl ?? "")
    ? getIframeYoutubeLink(videoUrl)
    : undefined;

  const videoComponent = (
    <iframe
      title="Video"
      src={youtubeIframeLink}
      style={{ border: 0 }}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  );

  const placeholderVideoComponent = (
    <Center>
      <Stack gap={"xs"} align="center">
        <Title order={3}>Видео не загружено</Title>
        <Text>Добавьте ссылку вашего видео с Youtube</Text>
      </Stack>
    </Center>
  );

  return (
    <Paper>
      <Stack>
        <Title order={3}>Видео</Title>
        <Stack>
          <Paper bg={"gray.0"} p={0} style={{ overflow: "hidden" }}>
            <AspectRatio ratio={16 / 9}>
              {youtubeIframeLink ? videoComponent : placeholderVideoComponent}
            </AspectRatio>
          </Paper>
          <Group>
            <TextInput
              control={control}
              defaultValue={""}
              name={CARD_SETTINGS_NAMES.video}
              style={{ flexGrow: 1 }}
              placeholder="Например: https://www.youtube.com/watch?v=K4TOrB7at0Y"
            />
          </Group>
        </Stack>
      </Stack>
    </Paper>
  );
};
