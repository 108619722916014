import { Button, Center, Container, Stack, Title } from "@mantine/core";
import { Link } from "react-router-dom";

const ForbiddenPage = () => {
  return (
    <Container h={"100vh"} pos={"relative"}>
      <Center h={"100%"}>
        <Title
          fz={"35vw"}
          pos={"absolute"}
          style={{ opacity: 0.1, userSelect: "none", zIndex: -1 }}
        >
          403
        </Title>
        <Stack>
          <Title>Эта страница вам не доступна</Title>
          <Button component={Link} to={"/"}>
            На главную
          </Button>
        </Stack>
      </Center>
    </Container>
  );
};

export default ForbiddenPage;
