import { Button, Collapse, Group, Stack, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { CollapsedFieldProfile } from "packages/profileFields/types";
import { FC } from "react";
import { ProfileFields } from "..";

export const CollapsedFieldComp: FC<CollapsedFieldProfile> = ({ title, body }) => {
  const [opened, { toggle }] = useDisclosure(false);
  return (
    <Stack>
      <Group justify="space-between">
        <Text>Счет: {title}</Text>
        <Button onClick={toggle} variant="default" w={"fit-content"}>
          Посмотреть больше
        </Button>
      </Group>

      <Collapse in={opened}>
        <Stack mx={"md"} gap={"sm"}>
          <ProfileFields fields={body} inline />
        </Stack>
      </Collapse>
    </Stack>
  );
};
