import { createGetItemHook } from "shared/hooks/helpers/createGetItemHook";
import { useActualProjectDocumentsStore } from "stores/projects/useActualProjectDocuments";

export const useActualProjectDocuments = ({
  projectId,
  withoutAutoRequest,
}: {
  projectId: string;
  withoutAutoRequest?: boolean;
}) =>
  createGetItemHook({
    useStore: useActualProjectDocumentsStore,
    fetchKey: "fetchGetAll",
    customParams: { projectId: projectId },
  })({ withoutAutoRequest });
