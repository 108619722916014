import cx from "clsx";
import { Footer as FooterComponent } from "components/_layouts/Layout/Footer";
import styles from "./index.module.scss";

const col6 = cx(styles.col6, styles.withoutMAouto, styles.withoutPb);

export const Footer = () => (
  <FooterComponent />
  // <Box component={"footer"} bg={"#F6F7F9"} className={styles.wrapper}>
  //   <Divider />
  //   <Container h={"inherit"} size={"xxl"}>
  //     <Box className={styles.row}>
  //       <Group className={cx(col6, styles.footerLeftItems)}>
  //         <Anchor fw={700} to={"/faq"} component={Link}>
  //           FAQ
  //         </Anchor>
  //         <Anchor fw={700} to={"/support"} component={Link}>
  //           Поддержка
  //         </Anchor>
  //       </Group>
  //       <Box className={cx(col6, styles.footerRightItems)}>
  //         <Title ta={"right"} order={6} className={styles.footerCompanyName}>
  //           ООО "Капитализация"
  //         </Title>
  //         <Link target={"_blank"} to={"https://cbr.ru/rbr/rbr_fr/doc/?id=35990"} size={"xs"}>
  //           Платформа состоит в реестре инвестиционных платформ Банка России от 01.03.2023
  //         </Link>
  //       </Box>
  //     </Box>
  //   </Container>
  // </Box>
);
