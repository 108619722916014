import { FieldsProfile } from "packages/profileFields/types";
import { UserRole } from "shared/const/userRole";
import { useCheckAuth } from "shared/hooks/_auth/useCheckAuth";
import { useUser } from "shared/hooks/_user/useUser";
import { useVerifyEmail } from "shared/hooks/_user/useVerifyEmail";

export const useConfig = () => {
  const user = useUser()!;
  const { pending } = useCheckAuth();
  const { handleVerifyEmail, pending: verifyEmailPending } = useVerifyEmail();

  const fields: FieldsProfile = [
    {
      type: "text",
      label: "ФИО",
      text: `${user.lastName} ${user.firstName} ${user.middleName || ""}`,
    },
    {
      type: "phone",
      label: "Телефон",
      phone: user.id,
    },
    {
      type: "email",
      label: "Электронная почта",
      email: user.email,
      badgeStatus: user.checklist.emailVerified,
      button: {
        text: "Верифицировать",
        hidden: user.checklist.emailVerified,
        onClick: handleVerifyEmail,
        pending: verifyEmailPending,
      },
    },
    {
      type: "text",
      label: "Роль",
      text: user.role?.includes(UserRole.investor) ? "Инвестор" : "Проект",
    },
  ];

  return { fields, pending };
};
