import { MaybeNestedFieldsProfile } from "packages/profileFields/types";
import { useUserPassport } from "shared/hooks/_user/useUserPassport";

export const useConfig = () => {
  const { data, pending } = useUserPassport();

  if (!data) return { pending };

  const fields: MaybeNestedFieldsProfile = [
    {
      type: "text",
      label: "ФИО",
      text: `${data?.lastName} ${data?.firstName} ${data?.middleName}`,
    },
    {
      type: "text",
      label: "Серия и номер паспорта",
      text: `${data?.serial} ${data?.number}`,
    },
    [
      {
        type: "text",
        label: "Кем выдан",
        text: data?.issuedBy!,
      },
      {
        type: "text",
        label: "Дата выдачи",
        text: data?.dateOfIssue!,
      },
    ],
    {
      type: "text",
      label: "Код подразделения",
      text: data?.divisionCode!,
    },
    [
      {
        type: "text",
        label: "Дата рождения",
        text: data?.birthday!,
      },
      {
        type: "text",
        label: "Место рождения",
        text: data?.birthPlace!,
      },
    ],
    {
      type: "text",
      label: "Адрес регистрации",
      text: data?.registrationAddress!,
    },
  ];

  return { fields, pending };
};
