import React from "react";

type Props = {
  position: "top" | "bottom";
  offset: number;
};

function useWindowScrollToPosition(payload: Props): React.RefObject<HTMLDivElement>;

function useWindowScrollToPosition(offsetTop?: number): React.RefObject<HTMLDivElement>;
function useWindowScrollToPosition(payload?: Props | number) {
  const elementRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (!elementRef.current) {
      return;
    }
    const rect = elementRef.current.getBoundingClientRect();

    if (!payload) {
      window.scrollTo({ top: window.scrollY + rect.top });
      return;
    }

    if (typeof payload === "number") {
      window.scrollTo({ top: window.scrollY + rect.top + payload });
      return;
    }

    window.scrollTo({ top: window.scrollY + rect[payload.position] + payload.offset });
  }, [elementRef]);
  return elementRef;
}

export { useWindowScrollToPosition };
